.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

body {
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 2.4em;
  font-weight: 700;
  line-height: 140%;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 2em;
  font-weight: 700;
  line-height: 36px;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.6em;
  font-weight: 700;
  line-height: 30px;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.3em;
  font-weight: 500;
  line-height: 1.3em;
}

p {
  margin-bottom: 10px;
  font-size: 1em;
  line-height: 170%;
}

a {
  text-decoration: underline;
}

label {
  font-weight: 700;
  display: block;
}

.rich-data-content {
  z-index: 1;
  min-height: 100vh;
  background-color: #fff;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  margin-top: -56px;
  margin-bottom: -56px;
  padding-top: 64px;
  padding-left: 64px;
  padding-right: 64px;
  display: flex;
  position: relative;
}

.section {
  padding-bottom: 60px;
  position: relative;
}

.section.header {
  flex-direction: column;
  display: flex;
}

.body {
  background-color: #d8d8d8;
}

.rich-data-nav {
  z-index: 2;
  width: 180px;
  background-color: #f8f8f8;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.map {
  justify-content: center;
  display: flex;
  position: fixed;
  top: 56px;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.nav {
  z-index: 99999;
  height: 56px;
  background-color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.nav.narrow {
  padding-left: 48px;
  padding-right: 48px;
}

.section-link {
  z-index: -1;
  margin-top: -140px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.panel-toggle {
  z-index: 1;
  width: 44px;
  height: 44px;
  cursor: pointer;
  background-color: #fff;
  background-image: linear-gradient(to right, rgba(0, 0, 0, .05), rgba(0, 0, 0, 0) 16%);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-top: 12px;
  padding: 10px;
  transition: width .2s cubic-bezier(.23, 1, .32, 1), padding .2s cubic-bezier(.23, 1, .32, 1);
  display: flex;
  position: relative;
  box-shadow: 4px 0 8px rgba(0, 0, 0, .1);
}

.panel-toggle.cc-active {
  color: #39ad84;
}

.rich-data {
  z-index: 4;
  width: 1184px;
  max-width: 98%;
  display: none;
  position: relative;
  top: 56px;
  left: 0;
  right: auto;
}

.rich-data.webflow {
  display: flex;
  left: 0;
}

.location-highlight__value {
  text-align: center;
  justify-content: center;
  align-self: stretch;
  font-size: 1.2em;
  font-weight: 500;
  display: flex;
}

.map-location {
  z-index: 999;
  max-width: 85vw;
  background-color: rgba(255, 255, 255, .63);
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 8px;
  transition: all .4s;
  display: flex;
  position: absolute;
  top: 16px;
  bottom: auto;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, .2), 0 1px 6px -2px rgba(0, 0, 0, .3);
}

.map-location:hover {
  background-color: #fff;
}

.location-tag__loading-icon {
  width: 24px;
  opacity: 1;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  margin-right: -4px;
  padding: 4px;
  font-size: .8em;
  display: flex;
}

.location-tag__loading-icon.hidden {
  display: none;
}

.location-tag__type {
  height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 2px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  font-family: Verdana, Geneva, sans-serif;
  font-size: .6em;
  display: flex;
  position: absolute;
  top: -7px;
  bottom: auto;
}

.location-tag {
  height: 32px;
  max-width: 180px;
  min-width: 80px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #707070;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 3px;
  flex-direction: row;
  flex: none;
  justify-content: center;
  align-items: center;
  margin: 4px;
  padding-top: 3px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: .85em;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: relative;
  box-shadow: 0 2px 7px -4px #000;
}

.location-tag:hover {
  color: #fff;
  background-image: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .4));
  border-color: #ccc;
}

.location-tag.active {
  background-color: #333;
}

.location-highlight {
  border-top: 1px solid rgba(0, 0, 0, .15);
  border-right: 1px solid rgba(0, 0, 0, .15);
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-top: 4px;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}

.location-highlight.last {
  border-right-style: none;
}

.location-highlight__title {
  color: #666;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  justify-content: center;
  align-self: stretch;
  margin-bottom: 2px;
  font-size: .6em;
  line-height: 100%;
  display: flex;
}

.map-location__info {
  width: 100%;
  justify-content: center;
  align-self: center;
  display: flex;
}

.map-location__tags {
  max-width: 100%;
  flex-direction: row;
  display: flex;
  overflow: auto;
}

.rich-data-nav__list {
  width: 100%;
  flex-direction: column;
  padding-top: 120px;
  display: flex;
  overflow: auto;
}

.category-header__title {
  display: flex;
}

.rich-data-toggles {
  z-index: 2;
  width: 44px;
  height: 0;
  flex: none;
  position: -webkit-sticky;
  position: sticky;
  top: 68px;
}

.svg-icon {
  width: 24px;
  height: 24px;
  display: flex;
}

.svg-icon.small {
  width: 16px;
}

.svg-icon.large {
  width: 32px;
  height: 32px;
}

.svg-icon.svg-icon--no-size {
  width: auto;
  height: auto;
}

.svg-icon.is--walkthrough-arrow-left {
  width: 16px;
  margin-right: .5em;
}

.svg-icon.is--walkthrough-arrow-right {
  width: 16px;
  margin-left: 1em;
}

.svg-icon.is--cluster-icon {
  width: 12px;
  height: 12px;
  color: #fff;
  justify-content: center;
  display: none;
}

.svg-icon.is--cluster-icon.is--shown {
  display: flex;
}

.svg-icon.is--small {
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: center;
}

.cc-clear {
  margin: 0;
  padding: 0;
  line-height: 100%;
}

.location__breadcrumbs {
  flex-wrap: wrap;
  align-items: center;
  margin-top: -3px;
  margin-left: -3px;
  margin-right: -3px;
  display: flex;
}

.breadcrumb {
  width: auto;
  height: 24px;
  color: #fff;
  text-transform: capitalize;
  cursor: pointer;
  background-color: #707070;
  border-radius: 3px;
  flex: none;
  align-self: flex-start;
  align-items: center;
  margin: 3px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: .85em;
  transition: all .2s;
  display: flex;
}

.breadcrumb:hover {
  color: #fff;
  background-color: #333;
}

.location-facility__category {
  justify-content: space-between;
  align-items: flex-start;
  font-size: .85em;
  font-weight: 500;
  display: flex;
}

.location__facilities_title {
  text-transform: none;
  flex: 1;
  line-height: 140%;
  display: flex;
}

.location-facility__item_name {
  flex: 1;
  padding-left: 4px;
  display: flex;
  overflow: hidden;
}

.location__facilities {
  border-top: 1px solid rgba(0, 0, 0, .06);
  border-bottom: 1px solid rgba(0, 0, 0, .06);
  flex-flow: wrap;
  align-self: stretch;
  margin-left: -6px;
  margin-right: -6px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  position: relative;
}

.location-facility__item_value {
  justify-content: flex-end;
  display: flex;
}

.location-facility__description {
  color: #999;
  border-top: 1px dashed #ccc;
  margin-top: 6px;
  padding-top: 6px;
  padding-right: 12px;
  font-size: .8em;
  line-height: 130%;
}

.location-facility__list {
  color: #666;
  border-top: 1px solid #ccc;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  margin-top: 6px;
  padding-top: 6px;
  font-size: .8em;
  display: flex;
}

.location-facility__card {
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 2px;
  flex-direction: column;
  padding: 8px 10px 12px;
  line-height: 100%;
  display: flex;
}

.location-facility {
  width: 25%;
  padding: 6px;
}

.location-facility__icon {
  width: 24px;
  height: 24px;
  flex: none;
  margin-right: 6px;
}

.location-facility__value {
  margin-top: 4px;
  margin-left: 6px;
}

.location-facility__list_item {
  width: 100%;
  min-height: 28px;
  color: #707070;
  border-bottom: 1px solid #e6e6e6;
  border-radius: 2px;
  grid-template-rows: auto;
  grid-template-columns: 4fr 1fr .75fr;
  grid-auto-columns: 1fr;
  align-items: center;
  line-height: 170%;
  text-decoration: none;
  display: flex;
}

.location-facility__list_item:hover {
  color: #333;
  background-color: rgba(0, 0, 0, .1);
  border-bottom-color: rgba(0, 0, 0, 0);
}

.location-facility__list_item.last {
  border-bottom-style: none;
}

.location-facility__header {
  align-items: flex-start;
  display: flex;
}

.location__title {
  text-transform: capitalize;
  margin-bottom: 24px;
}

.rich-data-bumper {
  width: 180px;
  background-color: #f8f8f8;
  flex: none;
  position: relative;
  top: 56px;
}

.rich-data-nav__toggle {
  z-index: 1;
  width: 44px;
  height: 44px;
  color: #fff;
  cursor: pointer;
  background-color: #707070;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 72px;
  bottom: auto;
  left: auto;
  right: -22px;
  box-shadow: 0 0 20px -14px #000;
}

.rich-data-nav__toggle:hover {
  background-color: #333;
  border-color: #f0f0f0;
}

.rich-data-nav__item {
  color: #707070;
  white-space: nowrap;
  border-right: 2px solid rgba(0, 0, 0, 0);
  justify-content: flex-end;
  align-items: center;
  font-size: .9em;
  text-decoration: none;
  display: flex;
}

.rich-data-nav__item:hover {
  color: #333;
  background-color: rgba(0, 0, 0, .1);
  border-right-color: #707070;
}

.rich-data-nav__item:active {
  background-color: rgba(0, 0, 0, .06);
}

.rich-data-nav__item.w--current {
  color: #333;
  white-space: nowrap;
  background-color: #fff;
  border-right-color: #39ad84;
}

.rich-data-nav__item.w--current:hover {
  background-color: #ebebeb;
}

.rich-data-nav__item-icon {
  width: 44px;
  height: 44px;
  flex: none;
  padding: 10px;
}

.rich-data-nav__item-text {
  width: 120px;
  color: #333;
  text-align: right;
  border-right: 1px solid rgba(0, 0, 0, .08);
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
}

.nav__shadow {
  width: 100%;
  height: 16px;
  background-image: linear-gradient(rgba(0, 0, 0, .08), rgba(0, 0, 0, .02) 34%, rgba(0, 0, 0, 0));
  position: absolute;
  top: auto;
  bottom: -16px;
  left: 0%;
  right: 0%;
}

.location__facilities_sources {
  width: 100%;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  margin-top: 16px;
  margin-left: 6px;
  margin-right: 6px;
  padding-bottom: 12px;
  display: flex;
}

.label {
  color: #333;
  flex: none;
  margin-left: 2px;
  margin-right: 2px;
  font-size: .85em;
}

.data-source {
  height: 28px;
  cursor: pointer;
  border-radius: 2px;
  flex: none;
  align-items: center;
  margin-bottom: 2px;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 6px;
  padding-right: 6px;
  text-decoration: underline;
  display: flex;
}

.data-source:hover {
  color: #000;
  background-color: rgba(0, 0, 0, .1);
}

.sticky-header {
  z-index: 999;
  width: 100%;
  justify-content: space-between;
  align-self: flex-start;
  align-items: center;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
}

.category-header__text {
  align-items: center;
  display: flex;
}

.category-header__icon {
  height: 44px;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 12px;
  display: flex;
}

.category-header {
  border-bottom: 1px solid #c7c7c7;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 32px;
  padding-bottom: 12px;
  display: flex;
}

.sub-category-header {
  border-top: 1px solid #c7c7c7;
  flex-direction: column;
  flex: 1;
  padding-top: 32px;
  display: flex;
}

.sub-category-header.first {
  border-top-style: none;
  padding-top: 0;
}

.indicator__title_wrapper {
  position: relative;
}

.sub-category-header__title {
  margin-bottom: 24px;
  display: flex;
  position: relative;
}

.sub-category-header__key-metrics {
  border-bottom: 1px solid #c7c7c7;
  flex-flow: wrap;
  align-items: stretch;
  padding-bottom: 24px;
  display: flex;
}

.sub-category-header__key-metrics_title {
  width: 100%;
  color: #999;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: 8px;
  margin-bottom: 12px;
  font-size: .9em;
  line-height: 140%;
  display: flex;
}

.h3__line-v {
  z-index: -1;
  height: 3px;
  display: flex;
  position: absolute;
  top: auto;
  left: -2px;
  right: 0%;
}

.sub-category-header__description {
  margin-bottom: 8px;
  display: flex;
}

.link-block {
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 2px;
  align-items: center;
  padding: 8px;
  display: flex;
}

.link-block:hover {
  background-color: rgba(0, 0, 0, .1);
}

.key-metric {
  width: 25%;
  padding: 6px;
}

.key-metric__card {
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 2px;
  flex-direction: column;
  padding: 10px 10px 12px;
  line-height: 100%;
  display: flex;
  overflow: hidden;
}

.key-metric__value {
  margin-bottom: 2px;
  font-size: 1.8em;
  line-height: 1em;
  display: flex;
}

.key-metric__title {
  color: #666;
  flex-wrap: wrap;
  font-size: .8em;
  line-height: 1.3em;
  display: flex;
}

.key-metric__description {
  color: #999;
  border-top: 1px dashed #ccc;
  margin-top: 6px;
  padding-top: 6px;
  font-size: .8em;
  line-height: 130%;
}

.dropdown-menu__icon {
  width: 24px;
  height: 24px;
}

.bar-chart__label_position {
  white-space: nowrap;
  justify-content: center;
  margin-top: 6px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.dropdown-menu__label {
  color: #999;
  letter-spacing: .1em;
  text-transform: uppercase;
  margin-bottom: 6px;
  font-size: .7em;
  line-height: 1em;
  display: flex;
}

.profile-indicator__chart_description {
  max-width: 450px;
  color: #999;
  border-radius: 2px;
  flex: 0 auto;
  align-items: flex-start;
  margin-bottom: 6px;
  padding-top: 1em;
  font-size: .8em;
  display: flex;
}

.profile-indicator {
  border-bottom: 1px solid #c7c7c7;
  flex-direction: column;
  flex: 1;
  padding-top: 24px;
  padding-bottom: 16px;
  display: flex;
}

.profile-indicator.last {
  border-bottom-style: none;
}

.profile-indicator__chart_source {
  border-radius: 2px;
  justify-content: flex-end;
  align-self: flex-start;
  align-items: center;
  margin-left: auto;
  padding: .8em 0 .8em 8px;
  font-size: .85em;
  display: flex;
}

.dropdown-menu__content {
  z-index: 10;
  width: 100%;
  max-height: 200px;
  color: #666;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  flex-direction: column;
  padding-top: 4px;
  padding-bottom: 4px;
  display: none;
  position: absolute;
  top: -4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, .08);
}

.dropdown-menu__content.scroll-element {
  overflow: auto;
}

.dropdown-menu__content.position-top {
  display: none;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.dropdown-menu__content.position-top.scroll-element.show {
  display: flex;
}

.dropdown-menu__content.dropdown-menu__content--top {
  top: auto;
  bottom: -4px;
}

.profile-indicator__chart {
  z-index: 1;
  flex-direction: column;
  margin-top: 16px;
  display: flex;
  position: relative;
}

.bar-chart__labels {
  width: 100%;
  color: #999;
  justify-content: space-between;
  padding-left: 100px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.bar-chart__label {
  width: 1px;
  background-color: rgba(0, 0, 0, .05);
  position: relative;
}

.profile-indicator__options {
  justify-content: flex-end;
  align-items: center;
  margin-left: 24px;
  display: flex;
}

.dropdown-menu__trigger {
  z-index: 9;
  width: 100%;
  height: 36px;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 3px;
  align-items: center;
  padding: 6px 6px 6px 12px;
  display: flex;
  position: relative;
}

.dropdown-menu__trigger:hover {
  background-color: #ebebeb;
}

.dropdown-menu__trigger.narrow {
  height: 35px;
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-menu__trigger.disabled {
  cursor: default;
}

.filter__dropdown_wrap {
  flex-direction: column;
  margin: 6px;
  display: flex;
}

.filter__dropdown_wrap.disabled {
  opacity: .4;
}

.dropdown-menu {
  z-index: 10;
  min-width: 200px;
  flex-direction: column;
  align-items: stretch;
  font-size: .9em;
  display: flex;
  position: relative;
}

.chart__footer_label {
  color: #333;
  flex: none;
}

.bar-chart__underline {
  height: 1px;
  background-color: #e6e6e6;
  margin-top: 16px;
  margin-left: 100px;
}

.dropdown__list_item {
  min-height: 32px;
  cursor: pointer;
  align-items: center;
  padding: 4px 12px;
  display: flex;
}

.dropdown__list_item:hover {
  color: #000;
  background-color: rgba(0, 0, 0, .06);
}

.dropdown__list_item:active {
  background-color: rgba(0, 0, 0, .24);
}

.dropdown__list_item.selected {
  color: #000;
  font-weight: 500;
}

.dropdown-menu__selected-item {
  flex: 1;
  align-self: stretch;
  align-items: center;
  line-height: 1.2;
  display: flex;
  overflow: hidden;
}

.profile-indicator__header {
  border-bottom: 1px solid rgba(0, 0, 0, .06);
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  margin-bottom: 24px;
  padding-bottom: 12px;
  display: flex;
}

.profile-indicator__header.demo {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding-bottom: 12px;
}

.profile-indicator__filters {
  z-index: 2;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;
  display: flex;
  position: relative;
}

.bar-chart__rows {
  padding-bottom: 6px;
  position: relative;
}

.bar-chart {
  width: 100%;
  font-size: 1em;
  position: relative;
}

.profile-indicator__title {
  flex: 1;
  align-self: center;
  align-items: flex-start;
  margin-top: -8px;
  display: flex;
}

.profile-indicator__chart_body {
  margin-top: 12px;
  padding-right: 44px;
  display: flex;
}

.indicator__chart {
  flex-flow: column wrap;
  display: flex;
}

.indicator__chart.full {
  width: 100%;
  margin-bottom: 16px;
}

.bar-chart__x-label {
  width: 100%;
  color: #999;
  text-transform: uppercase;
  justify-content: center;
  margin-top: 32px;
  padding-left: 100px;
  font-size: .7em;
  display: flex;
}

.profile-indicator__chart_footer {
  border-top: 1px dashed #e6e6e6;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.hover-menu__content_item--no-link {
  justify-content: flex-start;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 4px 12px;
  font-size: .85em;
  display: flex;
}

.hover-menu__content_list-item--last {
  min-width: 44px;
  color: #333;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: .85em;
  text-decoration: none;
  display: flex;
}

.hover-menu__content_list-item--last:hover {
  background-color: rgba(0, 0, 0, .1);
}

.icon--small {
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hover-menu__content_list--last {
  min-height: 32px;
  border-top: 1px solid #e6e6e6;
  flex-wrap: wrap;
  align-items: stretch;
  margin-top: 4px;
  margin-bottom: -4px;
  display: flex;
}

.hover-menu__icon {
  width: 36px;
  height: 36px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hover-menu__icon:hover {
  background-color: rgba(0, 0, 0, .1);
}

.hover-menu__content_item {
  min-height: 32px;
  color: #333;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 12px;
  font-size: .85em;
  text-decoration: none;
  display: flex;
}

.hover-menu__content_item:hover {
  background-color: #f0f0f0;
}

.hover-menu__content {
  z-index: 999;
  background-color: #fff;
  border-radius: 2px;
  display: none;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, .08);
}

.hover-menu__content.webflow {
  display: flex;
}

.hover-menu__content_list {
  min-height: 32px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  flex-wrap: wrap;
  align-items: stretch;
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
}

.hover-menu {
  z-index: 20;
  border-radius: 2px;
  flex-direction: column;
  display: flex;
  position: relative;
}

.hover-menu__content_list-item {
  min-width: 44px;
  color: #333;
  border-right: 1px solid #e6e6e6;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: .85em;
  text-decoration: none;
  display: flex;
}

.hover-menu__content_list-item:hover {
  background-color: #f0f0f0;
}

.hover-menu__content_list-item.active {
  background-color: #f0f0f0;
  border: 1px solid #666;
  margin-top: -1px;
  margin-bottom: -1px;
}

.hover-menu__content_list-item.active:hover {
  color: #000;
}

.hover-menu__content_list-item.last {
  border-right-color: rgba(0, 0, 0, 0);
}

.hover-menu__content_list-item.last.active {
  border-right-color: #666;
}

.hover-menu__content_wrapper {
  min-width: 200px;
  border: 1px solid #e6e6e6;
  padding-top: 4px;
  padding-bottom: 4px;
}

.content__item_title {
  margin-left: 6px;
}

.sticky-header__current-location {
  height: 44px;
  color: #fff;
  text-transform: capitalize;
  background-color: #666;
  background-image: none;
  border-radius: 100px;
  flex-direction: row;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-left: -16px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1em;
  text-decoration: none;
  display: flex;
  position: relative;
  box-shadow: 0 0 20px -14px #000;
}

.bar-chart__row_tooltip {
  width: 1px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: -29px;
  left: 50%;
  right: 0%;
}

.bar-chart__tooltip_alt-value {
  border-left: 1px solid #f0f0f0;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 6px;
  padding-left: 6px;
}

.bar-chart__row_bar {
  z-index: 1;
  width: 30%;
  height: 100%;
  min-width: 1px;
  background-image: none;
  align-items: center;
  display: flex;
  position: relative;
}

.bar-chart__row_bar:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.bar-chart__row_bar._1 {
  width: 28%;
}

.bar-chart__tooltip_value {
  color: #000;
  border-left: 1px solid #f0f0f0;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 6px;
  padding-left: 6px;
  font-weight: 700;
}

.bar-chart__row {
  height: 24px;
  color: #999;
  cursor: pointer;
  background-color: rgba(241, 241, 241, 0);
  justify-content: flex-start;
  margin-bottom: 6px;
  transition: all .1s;
  display: flex;
}

.bar-chart__row:hover {
  color: #000;
}

.bar-chart__row_tooltip-card {
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  font-size: .8em;
  display: flex;
  box-shadow: 0 2px 8px -4px #000;
}

.bar-chart__row_label {
  width: 100px;
  max-width: 100px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  font-size: .8em;
  line-height: 120%;
  display: flex;
  overflow: hidden;
}

.bar-chart__row-wrapper {
  background-image: none;
  flex: 1;
  display: flex;
}

.bar-chart__row-wrapper:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .04), rgba(0, 0, 0, .04));
}

.bar-chart__row_tooltip-notch {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-style: none solid solid none;
  display: flex;
  position: absolute;
  bottom: -4px;
  transform: rotate(45deg);
  box-shadow: 1px 1px 4px -2px rgba(0, 0, 0, .86);
}

.bar-chart__main {
  position: relative;
}

.bar-chart__row_value {
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  font-size: .8em;
  display: flex;
}

.bar-chart__label_value {
  font-size: .85em;
}

.search__dropdown_content {
  flex-direction: column;
  align-self: stretch;
  display: flex;
  overflow: hidden;
}

.search__list-item_location-parent {
  color: rgba(0, 0, 0, .32);
  padding-left: 12px;
  font-size: .85em;
  display: flex;
  overflow: hidden;
}

.nav__search_button {
  width: 60px;
  height: 37px;
  color: #fff;
  background-color: #707070;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
  justify-content: center;
  align-items: center;
  margin-top: -1px;
  margin-right: -1px;
  display: flex;
}

.search__list-item_location-type {
  opacity: .47;
  flex: none;
  justify-content: flex-end;
  font-size: .85em;
  display: flex;
  overflow: hidden;
}

.nav__content_title {
  height: 44px;
  align-self: center;
  align-items: center;
  display: flex;
}

.search__list-item_location-name {
  flex: none;
  display: flex;
  overflow: hidden;
}

.search__dropdown_list-item {
  height: 32px;
  max-width: 100%;
  grid-column-gap: 8px;
  grid-row-gap: 16px;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 2px;
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: 1.5fr 1fr .75fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 12px;
  padding-right: 12px;
  display: grid;
}

.search__dropdown_list-item:hover, .search__dropdown_list-item.active {
  color: #000;
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.nav__profile-logo {
  height: 44px;
  border-radius: 4px;
  display: flex;
  position: relative;
}

.search__dropdown_results-label {
  text-transform: uppercase;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 6px;
  font-size: .85em;
  display: flex;
}

.nav__search_dropdown {
  max-height: 450px;
  border-radius: 3px;
  flex-direction: row;
  display: none;
  position: relative;
  top: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .2);
}

.nav__search_dropdown.webflow {
  display: flex;
}

.nav__menu-trigger {
  width: 44px;
  height: 44px;
  cursor: pointer;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  transition: all .2s;
  display: flex;
}

.nav__menu-trigger:hover {
  color: #39ad84;
  border-radius: 0;
}

.nav__search_input {
  height: 100%;
  flex: 1;
  align-items: stretch;
  display: flex;
  position: relative;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
}

.nav__search_input:active {
  background-color: #f0f0f0;
}

.location__search_input {
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  margin-bottom: 0;
  padding: 0 8px 0 12px;
  font-size: 1.2em;
}

.location__search_input:hover {
  background-color: rgba(0, 0, 0, .04);
}

.nav__content_menu {
  align-items: center;
  display: flex;
}

.search__input {
  color: #333;
  flex: 1;
  align-self: stretch;
  margin-bottom: 0;
  display: flex;
}

.location__search_loading {
  width: 36px;
  height: 36px;
  opacity: .4;
  padding: 8px;
  display: none;
  position: absolute;
  left: auto;
  right: 60px;
}

.location__search_loading.hidden {
  display: none;
}

.search__dropdown_plate {
  width: 100%;
  max-height: 350px;
  background-color: #fff;
  border-radius: 3px;
  flex-direction: column;
  padding: 6px 8px;
  display: flex;
}

.nav__content {
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr 2.25fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-self: center;
  align-items: stretch;
  display: grid;
}

.nav__content.narrow {
  width: 960px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
}

.form {
  flex: 1;
  display: flex;
}

.nav__link {
  height: 32px;
  color: #707070;
  text-align: center;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  padding-right: 10px;
  font-size: .85em;
  text-decoration: none;
  display: flex;
}

.nav__link:hover {
  color: #333;
}

.nav__link:active {
  background-color: rgba(0, 0, 0, .06);
}

.nav__link.tutorial__open {
  color: #fff;
}

.nav__link.tutorial__open:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.search__dropdown_list {
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.nav__content_search {
  z-index: 99999999;
  width: 100%;
  height: 37px;
  max-width: 600px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  flex-direction: column;
  align-self: center;
  align-items: stretch;
  display: flex;
  position: relative;
}

.nav__search-deselect {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: none;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.more-info {
  z-index: 1;
  width: 20px;
  border-radius: 100%;
  position: relative;
}

.more-info:hover {
  color: #303030;
}

.point-mapper {
  z-index: 2;
  width: 300px;
  background-color: #fff;
  font-family: Roboto, sans-serif;
  display: none;
  position: fixed;
  top: 56px;
  bottom: 0%;
  left: 0%;
  right: auto;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, .2), 1px 1px 6px -2px rgba(0, 0, 0, .3);
}

.point-mapper.webflow {
  z-index: 3;
  display: flex;
  left: 0;
}

.point-mapper-toggles {
  width: 44px;
  height: 0;
  flex: none;
  position: absolute;
  top: 12px;
  left: 100%;
}

.panel-toggles {
  z-index: 1;
  position: fixed;
  top: 68px;
  bottom: auto;
  left: 0%;
  right: auto;
}

.data-mapper {
  z-index: 3;
  min-width: 300px;
  background-color: #fff;
  display: none;
  position: fixed;
  top: 56px;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.data-mapper.webflow {
  display: flex;
  left: 0;
}

.point-mapper-content {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 2px;
  padding: 22px 14px 14px 18px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.point-mapper-content.narrow-scroll {
  z-index: 1;
  overflow: scroll;
}

.data-mapper-toggles {
  width: 44px;
  height: 0;
  flex: none;
  position: absolute;
  top: 12px;
  left: 100%;
}

.point-mapper__h1_content {
  flex-direction: column;
  align-self: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

.point-mapper__h2_line-h {
  z-index: 1;
  width: 8px;
  height: 1px;
  background-color: rgba(0, 0, 0, .1);
  margin-top: -1px;
  margin-left: 5px;
  position: absolute;
  left: -16px;
}

.point-mapper__h2_loading {
  width: 24px;
  height: 24px;
  opacity: .4;
  flex: none;
  justify-content: flex-end;
  padding: 2px;
  display: flex;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 10px;
}

.point-mapper__h2_loading.hidden {
  display: none;
}

.point-mapper__h2_label {
  flex-direction: column;
  flex: 1;
  margin-top: 6px;
  margin-bottom: 6px;
  display: flex;
  overflow: hidden;
}

.point-mapper__h2 {
  height: 38px;
  color: #666;
  cursor: pointer;
  background-image: linear-gradient(#f0f0f0, #f0f0f0);
  border-radius: 2px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 10px;
  padding-right: 36px;
  font-size: .85em;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  position: relative;
}

.point-mapper__h2:hover {
  color: #000;
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.last {
  margin-bottom: 12px;
}

.point-mapper__h2.theme-1.active {
  background-image: linear-gradient(#dee7ff, #dee7ff), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-2 {
  background-image: linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-2:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-2.active {
  background-image: linear-gradient(#eadbfa, #eadbfa), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-2.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#eadbfa, #eadbfa), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-3:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-3.active {
  background-image: linear-gradient(#ffd1e7, #ffd1e7), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-3.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#ffd1e7, #ffd1e7), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-4.active {
  background-image: linear-gradient(#ffd7d7, #ffd7d7), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-4.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#ffd7d7, #ffd7d7), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-5.active {
  background-image: linear-gradient(#ffe3fc, #ffe3fc), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-5.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#ffe3fc, #ffe3fc), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-6.active {
  background-image: linear-gradient(#ffeadd, #ffeadd), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-6.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#ffeadd, #ffeadd), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-7.active {
  background-image: linear-gradient(#fff8e4, #fff8e4), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-7.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#fff8e4, #fff8e4), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-8.active {
  background-image: linear-gradient(#cef3b2, #cef3b2), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-8.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#cef3b2, #cef3b2), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-9.active {
  background-image: linear-gradient(#cdfaf2, #cdfaf2), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-9.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#cdfaf2, #cdfaf2), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-10.active {
  background-image: linear-gradient(#d6eaeb, #d6eaeb), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h2.theme-10.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#d6eaeb, #d6eaeb), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1 {
  z-index: 1;
  max-width: 293px;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.point-data__h2_line-v {
  width: 1px;
  background-color: rgba(0, 0, 0, .11);
  margin-bottom: 32px;
  margin-left: 12px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.point-mapper__h2_wrapper {
  padding-left: 23px;
  position: relative;
}

.point-data__h1_name {
  width: 100%;
  color: #333;
  flex: 1;
  align-items: center;
  margin-bottom: 0;
  margin-right: .5em;
  padding-top: .5em;
  padding-bottom: .5em;
  font-size: .9em;
  font-weight: 500;
  line-height: 1.2;
  display: flex;
  overflow: hidden;
}

.point-mapper__h2_load-complete {
  width: 24px;
  height: 24px;
  opacity: .4;
  justify-content: center;
  align-items: center;
  padding: 2px;
  display: flex;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 10px;
}

.point-mapper__h2_load-complete.hidden {
  display: none;
}

.point-mapper__h2_name {
  line-height: 1.2em;
}

.point-mapper__h1_checkbox {
  z-index: 1;
  align-items: center;
  display: none;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 36px;
}

.point-mapper__h1_checkbox.is--shown {
  display: flex;
}

.point-mapper__h1_trigger {
  width: 100%;
  min-height: 36px;
  cursor: pointer;
  background-image: linear-gradient(#f0f0f0, #f0f0f0);
  border-radius: 2px;
  align-self: stretch;
  align-items: center;
  margin-bottom: 8px;
  padding-left: 8px;
  padding-right: 36px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.point-mapper__h1_trigger:hover {
  color: #000;
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-1 {
  color: #3a70ff;
}

.point-mapper__h1_trigger.theme-1.active {
  background-image: linear-gradient(#dee7ff, #dee7ff), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-1.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#dee7ff, #dee7ff), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-2 {
  color: #993aff;
}

.point-mapper__h1_trigger.theme-2.active {
  background-image: linear-gradient(#eadbfa, #eadbfa), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-2.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#eadbfa, #eadbfa), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-3 {
  color: #ad356d;
}

.point-mapper__h1_trigger.theme-3.active {
  background-image: linear-gradient(#ffd1e7, #ffd1e7), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-3.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#ffd1e7, #ffd1e7), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-4 {
  color: #f04f4f;
}

.point-mapper__h1_trigger.theme-4.active {
  background-image: linear-gradient(#ffd7d7, #ffd7d7), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-4.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#ffd7d7, #ffd7d7), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-5 {
  color: #ff3a8c;
}

.point-mapper__h1_trigger.theme-5.active {
  background-image: linear-gradient(#ffe3fc, #ffe3fc), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-5.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#ffe3fc, #ffe3fc), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-6 {
  color: #ff893a;
}

.point-mapper__h1_trigger.theme-6.active {
  background-image: linear-gradient(#ffeadd, #ffeadd), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-6.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#ffeadd, #ffeadd), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-7 {
  color: #e7bc20;
}

.point-mapper__h1_trigger.theme-7.active {
  background-image: linear-gradient(#fff8e4, #fff8e4), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-7.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#fff8e4, #fff8e4), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-8 {
  color: #48c555;
}

.point-mapper__h1_trigger.theme-8.active {
  background-image: linear-gradient(#cef3b2, #cef3b2), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-8.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#cef3b2, #cef3b2), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-9 {
  color: #2ccaad;
}

.point-mapper__h1_trigger.theme-9.active {
  background-image: linear-gradient(#cdfaf2, #cdfaf2), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-9.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#cdfaf2, #cdfaf2), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-10 {
  color: #0a8286;
}

.point-mapper__h1_trigger.theme-10.active {
  background-image: linear-gradient(#d6eaeb, #d6eaeb), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.theme-10.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), linear-gradient(#d6eaeb, #d6eaeb), linear-gradient(#f0f0f0, #f0f0f0);
}

.point-mapper__h1_trigger.point-mapper__h1--default-closed {
  text-decoration: none;
}

.point-mapper__h1_trigger.is--toggle-all {
  padding-right: 72px;
}

.custom-checkbox {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: #fff;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0;
  margin-left: 0;
  margin-right: 8px;
  display: flex;
}

.custom-checkbox.w--redirected-checked {
  background-color: #39ad84;
  background-size: 20px 20px;
  border-color: #39ad84;
}

.custom-checkbox.point-data-toggle {
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0);
  margin-right: 4px;
  padding: 6px 2px 2px;
}

.point-mapper__h2_source {
  color: rgba(0, 0, 0, .3);
  margin-top: 3px;
  margin-bottom: -2px;
  font-size: .85em;
  line-height: 100%;
  display: flex;
}

.point-data__h1_icon {
  height: 24px;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.point-mapper-content__list {
  height: auto;
}

.point-mapper-content__header {
  border-bottom: 1px solid #ececec;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 10px;
  display: flex;
}

.point-data__header_icon {
  align-self: center;
  margin-left: 8px;
  margin-right: 12px;
  display: flex;
}

.point-data__header_title {
  font-size: 1.2em;
  font-weight: 700;
  display: flex;
}

.utility-page-wrap {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.utility-page-content {
  width: 260px;
  text-align: center;
  flex-direction: column;
  display: flex;
}

.utility-page-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.data-mapper-content {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 2px;
  padding: 22px 14px 14px 20px;
}

.data-mapper-content.narrow-scroll {
  z-index: 1;
  width: 300px;
  overflow: scroll;
}

.data-mapper-content__header {
  border-bottom: 1px solid #ececec;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 10px;
  display: flex;
}

.data-mapper-content__list {
  height: auto;
  position: relative;
}

.data-mapper-content__list.is--loading {
  filter: grayscale();
}

.data-category {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 8px;
  display: flex;
  position: relative;
}

.data-category__h2_trigger {
  height: 32px;
  color: #333;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 2px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
  padding-left: 12px;
  padding-right: 8px;
  font-size: .85em;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.data-category__h2_trigger:hover {
  color: #000;
  background-color: rgba(0, 0, 0, .1);
}

.data-category__h2_trigger.w--current {
  font-size: 1em;
}

.data-category__h1_wrapper {
  flex-direction: column;
  flex: 1;
  margin-top: 8px;
  padding-left: 12px;
  display: flex;
  position: relative;
}

.data-category__h3 {
  z-index: 3;
  cursor: pointer;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.data-category__h3.active {
  color: #333;
  background-color: rgba(57, 173, 132, .2);
  font-weight: 500;
}

.data-category__h3.active:hover {
  background-color: rgba(57, 173, 132, .3);
}

.data-category__h2_wrapper {
  padding-left: 16px;
  position: relative;
}

.data-category__h1_trigger {
  height: 36px;
  color: #fff;
  cursor: pointer;
  background-color: #f0f0f0;
  background-image: none;
  border-radius: 2px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  font-size: 14px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.data-category__h1_trigger:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .05), rgba(0, 0, 0, .05));
}

.data-category__h1_icon {
  height: 24px;
  color: #333;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.data-category__h2 {
  z-index: 2;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.data-category__h1_header {
  flex: 1;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.data-category__h1_toggle {
  width: 36px;
  height: 36px;
  flex-direction: column;
  flex: none;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.data-category__h1_content {
  height: auto;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.data-category__h2_content {
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.data-category__h1_title {
  letter-spacing: .3px;
  flex-direction: column;
  flex: 1;
  margin-right: 12px;
  font-size: .9em;
  font-weight: 500;
  display: flex;
  overflow: hidden;
}

.toggle-icon-v--last {
  width: 36px;
  height: 36px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.toggle-icon-v--last.is--open, .toggle-icon-v--last.is--closed {
  display: flex;
}

.toggle-icon-v--first {
  width: 36px;
  height: 36px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.toggle-icon-v--first.is--open, .toggle-icon-v--first.is--closed {
  display: none;
}

.data-mapper-content__description {
  color: #707070;
  margin-bottom: 16px;
  font-size: .9em;
}

.data-mapper-content__no-data {
  color: #666;
  background-color: #f0f0f0;
  align-items: center;
  margin-bottom: 16px;
  padding: 6px;
  font-size: .9em;
  font-weight: 500;
  display: flex;
}

.no-data__icon {
  padding-right: 6px;
}

.point-mapper-content__description {
  color: #707070;
  margin-bottom: 16px;
  font-size: .9em;
}

.no-data {
  color: #666;
  background-color: #f0f0f0;
  border-radius: 2px;
  align-items: center;
  padding: 4px 6px;
  font-size: .9em;
  display: flex;
}

.search__dropdown_scroll {
  overflow: auto;
}

.search__dropdown_scroll.narrow-scroll {
  margin-right: -4px;
  padding-right: 4px;
}

.map-options {
  z-index: 999;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, .2), 0 1px 6px -2px rgba(0, 0, 0, .3);
}

.map-options__legend {
  padding: 10px;
  display: flex;
}

.map-options__filters {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}

.map-options__legend_wrap {
  height: 19px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-right: -2px;
  display: grid;
}

.map_legend-block {
  height: 19px;
  text-align: center;
  letter-spacing: .2px;
  text-transform: uppercase;
  background-color: #203761;
  border-radius: 2px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: .6em;
  display: flex;
  overflow: hidden;
}

.map_legend-block.light {
  color: #fff;
}

.map-options__filters_header {
  height: 36px;
  padding-right: 72px;
  display: flex;
  position: relative;
}

.filters__header_name {
  align-self: center;
  font-weight: 500;
  overflow: hidden;
}

.filters__header_icon {
  height: 24px;
  flex: none;
  padding-right: 8px;
}

.map-options__filters_content {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, .1);
  flex-wrap: wrap;
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
}

.mapping-options__filter {
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  margin-right: 10px;
  display: flex;
}

.mapping-options__filter.disabled {
  opacity: .4;
  cursor: auto;
}

.mapping-options__filter_label {
  color: #999;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: .7em;
  line-height: 1em;
  display: flex;
}

.mapping-options__filter_menu {
  z-index: 10;
  flex-direction: column;
  align-items: stretch;
  font-size: .9em;
  display: flex;
  position: relative;
}

.map-options__legend_label {
  color: #999;
  letter-spacing: .1em;
  text-transform: uppercase;
  align-items: center;
  margin-right: 4px;
  font-size: .7em;
  line-height: 1em;
  display: flex;
}

.filters__header_toggle {
  width: 36px;
  height: 36px;
  cursor: pointer;
  border-radius: 2px;
  flex-direction: column;
  flex: none;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 36px;
  overflow: hidden;
}

.filters__header_toggle:hover {
  background-color: rgba(0, 0, 0, .06);
}

.map-area {
  width: 100%;
}

.filter__header_sub-indicator {
  flex: 1;
  align-items: center;
  padding-left: 11px;
  padding-right: 11px;
  display: flex;
  overflow: hidden;
}

.filters__header_close {
  width: 36px;
  height: 36px;
  cursor: pointer;
  border-radius: 2px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
  overflow: hidden;
}

.filters__header_close:hover {
  background-color: rgba(0, 0, 0, .06);
}

.styles {
  z-index: 2147483647;
  min-height: 100vh;
  background-color: #fff;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.main {
  z-index: 1;
  height: 100vh;
  position: relative;
}

.styles__wrapper {
  width: 1024px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
}

.styles__area_block {
  min-width: 33.333%;
  flex-direction: column;
  padding: 32px 24px;
  display: flex;
}

.styles__area_block.wide {
  width: 100%;
}

.styles__area_block.horizontal {
  flex-direction: row;
}

.styles__area_block.horizontal.wide {
  position: relative;
}

.styles__area_block.test {
  min-width: auto;
}

.styles__section {
  flex-wrap: wrap;
  flex: 1;
  display: flex;
}

.styles__title {
  width: 100%;
  color: #000;
  border-bottom: 1px solid #b8b8b8;
  flex: none;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 16px;
  font-size: 1.4em;
  font-weight: 700;
  display: flex;
}

.hidden {
  display: none;
}

.data-category__h3_load-complete {
  width: 24px;
  height: 24px;
  opacity: .4;
  justify-content: center;
  align-items: center;
  padding: 2px;
  display: flex;
  position: absolute;
  left: auto;
  right: 4px;
}

.data-category__h3_load-complete.hidden {
  display: none;
}

.data-category__h3_loading {
  width: 24px;
  height: 24px;
  opacity: .4;
  justify-content: flex-end;
  padding: 2px;
  display: flex;
  position: absolute;
  left: auto;
  right: 4px;
}

.data-category__h3_loading.hidden {
  display: none;
}

.rich-data__shadow {
  width: 16px;
  height: 100%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, .08), rgba(0, 0, 0, .02) 34%, rgba(0, 0, 0, 0));
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: -16px;
}

.location-facility__name {
  margin-top: 4px;
  overflow: hidden;
}

.styles__subsection {
  width: 100%;
  color: #000;
  border-bottom: 1px solid #f0f0f0;
  flex: none;
  align-items: center;
  margin-bottom: 6px;
  padding: 32px 16px 6px;
  font-size: 1.2em;
  font-weight: 500;
  display: flex;
}

.location__facilities_no-data {
  width: 100%;
  margin-left: 6px;
  margin-right: 6px;
}

.location__facilities_no-data.hidden {
  display: none;
}

.point-mapper-content__no-data {
  margin-bottom: 16px;
}

.location__sources_no-data {
  margin-left: 3px;
  margin-right: 3px;
}

.location-tag__name {
  width: 100%;
}

.location__facilities_loading {
  width: 100%;
  height: 32px;
  margin-left: 6px;
  margin-right: 6px;
  display: block;
}

.location__facilities_loading.hidden {
  display: none;
}

.loading {
  height: 100%;
  color: #666;
  background-color: #f0f0f0;
  border-radius: 2px;
  flex: 1;
  align-items: center;
  padding: 4px 6px;
  font-size: .9em;
  display: flex;
}

.loading__icon {
  width: 24px;
  padding-right: 6px;
}

.location__sources_loading {
  margin-left: 3px;
  margin-right: 3px;
}

.point-mapper-content__loading {
  height: 32px;
  margin-bottom: 16px;
}

.data-mapper-content__loading {
  height: 32px;
  color: #666;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 500;
  display: flex;
}

.data-mapper-content__loading.hidden {
  display: none;
}

.location-tag__loading {
  height: 32px;
  max-width: 140px;
  min-width: 60px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #707070;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 3px;
  flex-direction: row;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  margin: 4px;
  padding-top: 3px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: .85em;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: relative;
  box-shadow: 0 2px 7px -4px #000;
}

.location-tag__loading:hover {
  color: #fff;
  background-image: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .4));
  border-color: #ccc;
}

.location-tag__loading.hidden {
  display: none;
}

.location-facility__item_download {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 0 4px;
  display: flex;
}

.map-tooltip__points {
  border-top: 1px solid #e6e6e6;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  margin-top: 6px;
  padding-top: 4px;
  font-size: .85em;
  display: flex;
}

.tooltip__points_label {
  flex: 1;
  margin-right: 16px;
  display: flex;
}

.tooltip__notch {
  z-index: 0;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  justify-content: center;
  position: absolute;
  bottom: -7px;
  left: auto;
  right: auto;
  transform: rotate(45deg);
  box-shadow: 3px 3px 8px -4px rgba(0, 0, 0, .5);
}

.tooltip__value_label {
  align-self: stretch;
  margin-bottom: .2em;
  padding-left: 4px;
  padding-right: 8px;
  display: flex;
}

.map-tooltip__value {
  min-width: 180px;
  border-top: 1px solid #e6e6e6;
  flex-direction: column;
  align-self: stretch;
  align-items: stretch;
  margin-top: 6px;
  padding-top: 6px;
  font-size: .85em;
  line-height: 1.4;
  display: flex;
}

.map-tooltip__value.hidden {
  display: none;
}

.map-tooltip__name {
  text-align: center;
  justify-content: center;
  align-self: stretch;
  font-size: .85em;
  font-weight: 700;
  line-height: 1.4em;
  display: flex;
}

.map-tooltip {
  z-index: 1;
  max-width: 260px;
  min-height: 32px;
  min-width: 120px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 10px 8px;
  font-weight: 500;
  display: flex;
  position: relative;
  box-shadow: 0 2px 8px -4px rgba(0, 0, 0, .5);
}

.map-tooltip__geography-chip {
  min-height: 14px;
  color: #fff;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 100px;
  align-items: center;
  padding: 2px 4px 2px 5px;
  font-size: .65em;
  line-height: 100%;
  display: flex;
  position: absolute;
  top: -8px;
  left: auto;
  right: auto;
}

.tooltip__value_detail {
  color: #999;
  margin-left: 3px;
}

.tooltip__point_item {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  flex: 1;
  justify-content: space-between;
  font-weight: 500;
  line-height: 170%;
  display: flex;
}

.tooltip__point_item.last {
  border-bottom-style: none;
}

.facility-tooltip__header_icon {
  width: 24px;
  height: 24px;
  flex: none;
  margin-right: 6px;
}

.tooltip__facility-item_label {
  text-transform: capitalize;
  font-weight: 700;
  display: flex;
}

.facility-tooltip__item {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  flex-direction: column;
  padding-top: 6px;
  padding-bottom: 4px;
  font-weight: 500;
  line-height: 150%;
  display: flex;
}

.facility-tooltip__item.last {
  border-bottom-style: none;
}

.facility-tooltip__close {
  z-index: 1;
  width: 36px;
  height: 36px;
  color: rgba(0, 0, 0, .63);
  cursor: pointer;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 2px;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.facility-tooltip__close:hover {
  color: #000;
}

.facility-tooltip__items {
  border-top: 1px solid #e6e6e6;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: .85em;
  display: flex;
}

.facility-tooltip__header_name {
  flex: 1;
  align-self: stretch;
  align-items: center;
  font-size: .85em;
  font-weight: 700;
  line-height: 1.4em;
  display: flex;
}

.facility-tooltip__header {
  flex: none;
  align-self: stretch;
  padding-right: 32px;
  display: flex;
}

.facility-tooltip {
  z-index: 1;
  max-height: 60vh;
  max-width: 350px;
  min-height: 32px;
  min-width: 110px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  padding: 8px;
  font-weight: 500;
  display: flex;
  position: relative;
  box-shadow: 0 2px 8px -4px rgba(0, 0, 0, .5);
}

.facility-tooltip.demo {
  position: absolute;
  top: 15%;
  left: 37%;
}

.facility-tooltip.demo._2 {
  left: 16%;
}

.facility-tooltip.is--cluster {
  padding: 0;
}

.tooltip__facility-item_value {
  font-weight: 400;
  display: flex;
}

.map-options__legend_loading {
  height: 19px;
  min-width: 60px;
  text-align: center;
  letter-spacing: .2px;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, .1);
  border-radius: 2px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: .6em;
  display: flex;
}

.map-options__legend_loading.hidden {
  display: none;
}

.legend-block__loading-icon {
  width: 24px;
  opacity: 1;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  margin-right: 4px;
  padding: 4px;
  font-size: .8em;
  display: flex;
}

.sub-category-header__key-metrics_wrap {
  flex-wrap: wrap;
  flex: 1;
  margin-left: -6px;
  margin-right: -6px;
  display: flex;
}

.category-header__content {
  justify-content: space-between;
  display: flex;
}

.category-header__description {
  color: #333;
  border-top: 1px dashed #ccc;
  margin-top: 12px;
  margin-bottom: -6px;
  padding-top: 16px;
}

.login {
  width: 400px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: #fff;
  border-radius: 3px;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 16px 24px 24px;
  display: grid;
  position: absolute;
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, .2);
}

.login__title {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 4.5fr 1fr;
  grid-auto-columns: 1fr;
  font-size: .8em;
}

.login__close {
  width: 44px;
  height: 44px;
  cursor: pointer;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-right: -12px;
  display: flex;
}

.login__close:hover {
  color: #39ad84;
  background-color: rgba(0, 0, 0, .1);
}

.clear {
  margin: 0;
  padding: 0;
}

.login__description {
  background-color: rgba(0, 0, 0, .1);
  padding: 12px;
}

.button {
  height: 44px;
  text-align: center;
  letter-spacing: .02em;
  cursor: pointer;
  background-image: none;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  display: flex;
}

.button:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.button.text {
  color: #333;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  display: flex;
}

.button.button--grey {
  color: #333;
  background-color: #f0f0f0;
}

.button.button--margin-right {
  margin-right: 12px;
}

.button.button--subtle {
  height: auto;
  color: #333;
  background-color: #f0f0f0;
  padding: .6em .7em;
  font-weight: 400;
  text-decoration: none;
}

.login-form__actions {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  margin-top: 16px;
  display: grid;
}

.login__form-checkbox {
  z-index: 1;
  cursor: pointer;
  align-items: center;
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0;
  display: flex;
}

.checkbox-label {
  flex: 1;
}

.text-field {
  border-radius: 2px;
}

.form-block {
  margin-bottom: 0;
}

.tutorial {
  z-index: 3;
  width: 450px;
  background-color: #fff;
  border-radius: 3px;
  flex-direction: column;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 10px 24px 16px;
  display: flex;
  position: absolute;
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, .2);
}

.tutorial__title {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 4.5fr 1fr;
  grid-auto-columns: 1fr;
  font-size: .7em;
}

.tutorial__close {
  width: 44px;
  height: 44px;
  cursor: pointer;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-right: -12px;
  display: flex;
}

.tutorial__slider {
  height: 400px;
  background-color: #fff;
  margin-bottom: 62px;
  margin-left: -24px;
  margin-right: -24px;
}

.left-arrow {
  width: 20%;
  height: 56px;
  cursor: default;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
  top: auto;
  bottom: -62px;
  left: 0%;
  right: auto;
}

.right-arrow {
  width: 80%;
  height: 56px;
  align-items: center;
  font-size: 1.1em;
  display: flex;
  top: auto;
  bottom: -62px;
  left: auto;
  right: 0%;
}

.tutorial__slide-content {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.slide-nav {
  height: 32px;
  transform: scale3d(1none, 1none, 1none);
  transform-style: preserve-3d;
  justify-content: center;
  align-items: flex-end;
  padding-top: 0;
  display: none;
  top: auto;
  bottom: -32px;
  left: 0%;
  right: 0%;
}

.tutorial__slide-info {
  z-index: 1;
  width: 100%;
  color: #fff;
  text-align: left;
  background-color: #333;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 24px;
  line-height: 140%;
  display: flex;
  position: relative;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .3);
}

.tutorial__slide_button {
  color: #333;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .1);
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.tutorial__slide_button:hover {
  color: #000;
  background-color: rgba(0, 0, 0, .1);
}

.tutorial__slide_button.next {
  color: #fff;
  background-color: #707070;
  margin-left: 12px;
}

.tutorial__slide_button.next:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.tutorial__slide_button.previous {
  width: 44px;
  height: 44px;
  margin-right: 12px;
}

.tutorial__slide_gradient {
  z-index: 1;
  height: 40px;
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, 0) 103%);
  position: absolute;
}

.tutorial__slide_gradient.bottom {
  background-image: linear-gradient(to top, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0) 103%);
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.tutorial__dismiss {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1.75fr .75fr;
  grid-auto-columns: 1fr;
  align-items: center;
  margin-top: 8px;
  display: grid;
}

.dismiss__no-show {
  height: 24px;
  align-items: center;
  display: flex;
}

.nav__link_icon {
  margin-left: 6px;
}

.login-modal {
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.login-modal.webflow {
  display: flex;
}

.login-modal.hidden {
  display: none;
}

.tutorial-modal {
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.tutorial-modal.webflow {
  display: flex;
}

.nav__link_text {
  flex: none;
  margin-left: 6px;
}

.html-embed {
  height: 24px;
}

.sticky-header__tutorial {
  width: 32px;
  height: 32px;
  color: #39ad84;
  cursor: pointer;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-right: -64px;
  display: flex;
  position: relative;
}

.sticky-header__tutorial:hover {
  background-color: rgba(57, 173, 132, .06);
}

.sticky-header__tutorial.hidden {
  display: none;
}

.location__header {
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
}

.location__tutorial {
  height: 32px;
  color: #333;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  font-size: .85em;
  display: flex;
  position: relative;
}

.location__tutorial:hover {
  background-color: rgba(0, 0, 0, .1);
}

.location__tutorial:active {
  background-color: rgba(0, 0, 0, .06);
}

.location__tutorial.hidden {
  display: none;
}

.location__tutorial_icon {
  margin-right: 2px;
}

.location__tutorial_text {
  margin-left: 2px;
  margin-right: 2px;
}

.tutorial__header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
}

.tutorial__slide-number {
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, .6);
  border-radius: 3px;
  align-items: center;
  padding: 2px 6px;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 16px;
  left: 24px;
  right: auto;
  box-shadow: 0 1px 6px -3px #000;
}

.slide-info__title {
  border-radius: 2px;
  padding: 1px 4px;
  font-weight: 500;
  display: inline-block;
}

.map-about {
  z-index: 998;
  height: 32px;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, .2);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
  padding-left: 6px;
  padding-right: 8px;
  font-size: .85em;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.map-about:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.map-about.hidden {
  display: none;
}

.map-about__profile-name {
  margin-left: 3px;
}

.container {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

.page-header {
  grid-column-gap: 12px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.map-about__icon {
  margin-right: 4px;
}

.nav__link-wrap {
  margin-right: -12px;
}

.login-form__checkbox {
  align-items: center;
  margin-right: 6px;
  padding-left: 0;
  display: flex;
}

.content-block {
  padding-bottom: 60px;
  padding-left: 48px;
  padding-right: 48px;
}

.content-block.first {
  margin-top: 56px;
  padding-top: 60px;
}

.content-block.bg-green {
  background-color: rgba(57, 173, 132, .05);
  padding-bottom: 40px;
}

.content-block.bg-green.first {
  margin-bottom: 40px;
  padding-top: 40px;
}

.content-block.footer {
  margin-top: auto;
}

.page-header__overline {
  letter-spacing: .2em;
  text-transform: uppercase;
  font-weight: 500;
}

.page-header__title {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.page-header__website-link {
  color: #39ad84;
  background-color: rgba(57, 173, 132, .2);
  border-radius: 2px;
  align-items: center;
  margin-top: 16px;
  padding: 5px 6px 4px 8px;
  text-decoration: none;
  display: flex;
}

.page-header__website-link:hover {
  color: #fff;
  background-color: #39ad84;
}

.page-header__website-link.hidden {
  display: none;
}

.page-header__website-link_icon {
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  display: flex;
}

.footer {
  background-color: #f0f0f0;
  padding-top: 60px;
  padding-bottom: 60px;
}

.footer-grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.footer-logo {
  height: 44px;
}

.footer-links {
  margin-top: 16px;
}

.footer-links__header {
  color: #39ad84;
  letter-spacing: .1em;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: .85em;
  font-weight: 700;
}

.footer-link {
  color: #707070;
  align-items: center;
  margin-bottom: 8px;
  text-decoration: none;
  display: flex;
}

.footer__disclaimer {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-top: 1px solid rgba(0, 0, 0, .1);
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 16px;
  padding-top: 32px;
  font-size: .85em;
  display: grid;
}

.location__description {
  color: #707070;
  letter-spacing: .1em;
  text-transform: uppercase;
  margin-top: -16px;
  margin-bottom: 24px;
  font-size: .85em;
}

.map-options__context {
  border-top: 1px dashed rgba(0, 0, 0, .1);
  justify-content: space-between;
  align-items: stretch;
  padding-left: 10px;
  padding-right: 10px;
  font-size: .8em;
  display: flex;
}

.map-options__context.hidden {
  display: none;
}

.map-option__context_text {
  color: #707070;
  flex: 1;
  align-self: center;
  padding-top: 6px;
  padding-bottom: 6px;
}

.map-options__context_tooltip {
  width: 36px;
  min-height: 36px;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: -11px;
  display: flex;
  position: relative;
}

.map-options__context_tooltip:hover {
  background-color: rgba(0, 0, 0, .1);
}

.tooltip {
  max-width: 300px;
  text-align: center;
  justify-content: center;
  display: none;
  position: absolute;
  top: 0;
}

.tooltip-card {
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  padding: 6px 8px;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  box-shadow: 0 2px 6px -2px rgba(0, 0, 0, .63);
}

.tooltip-card.dark {
  color: #fff;
  background-color: #333;
}

.tooltip-notch {
  width: 8px;
  height: 8px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: auto;
  bottom: -4px;
  left: auto;
  right: auto;
  transform: rotate(45deg);
  box-shadow: 4px 4px 6px -2px rgba(0, 0, 0, .63);
}

.tooltip-notch.dark {
  background-color: #333;
}

.tooltip-text {
  min-width: 160px;
  text-align: center;
}

.profile-logo {
  max-height: 40px;
  margin: auto;
}

.content {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.data-category__h3_trigger {
  height: 32px;
  color: #666;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 2px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 6px;
  padding-left: 12px;
  padding-right: 8px;
  font-size: .85em;
  text-decoration: none;
  display: flex;
  position: relative;
}

.data-category__h3_trigger:hover {
  background-color: rgba(0, 0, 0, .1);
}

.data-category__h3_trigger.active {
  color: #333;
  font-weight: 500;
}

.data-category__h3_trigger.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.data-category__h3_content {
  position: relative;
}

.data-category__h3_wrapper {
  margin-top: 2px;
  margin-bottom: 10px;
  padding-left: 16px;
  position: relative;
}

.data-category__h4_line-v {
  width: 1px;
  background-color: rgba(0, 0, 0, .11);
  margin-bottom: 15px;
  margin-left: 4px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.data-category__h4 {
  height: 32px;
  color: #666;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .03);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 2px;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: .85em;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  position: relative;
}

.data-category__h4:hover {
  color: #000;
  background-color: rgba(0, 0, 0, .12);
}

.data-category__h4.active {
  color: #333;
  font-weight: 500;
}

.data-category__h4.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.data-category__h4_line-h {
  z-index: 1;
  width: 8px;
  height: 1px;
  background-color: rgba(0, 0, 0, .1);
  margin-left: 4px;
  position: absolute;
  left: -16px;
}

.data-category__h4_loading {
  width: 24px;
  height: 24px;
  opacity: .4;
  justify-content: flex-end;
  padding: 2px;
  display: flex;
  position: absolute;
  left: auto;
  right: 4px;
}

.data-category__h4_loading.hidden {
  display: none;
}

.data-category__h4_load-complete {
  width: 24px;
  height: 24px;
  opacity: .4;
  justify-content: center;
  align-items: center;
  padding: 2px;
  display: flex;
  position: absolute;
  left: auto;
  right: 4px;
}

.data-category__h4_load-complete.hidden {
  display: none;
}

.bar-chart__tooltip_name {
  color: #333;
}

.panel-toggle__tooltip {
  height: 28px;
  color: #333;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 4px;
  align-self: center;
  align-items: center;
  margin-left: 12px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: .9em;
  display: none;
  position: absolute;
  top: auto;
  bottom: auto;
  left: 100%;
  right: auto;
  box-shadow: 0 1px 9px rgba(0, 0, 0, .2);
}

.panel-toggle__tooltip_text {
  font-weight: 500;
}

.panel-toggle__tooltip_notch {
  width: 8px;
  height: 8px;
  background-color: #fff;
  position: absolute;
  top: auto;
  bottom: auto;
  left: -4px;
  right: auto;
  transform: rotate(45deg);
}

.location__breadcrumbs_title {
  width: 100%;
  color: #cacaca;
  margin-left: 3px;
  font-size: .85em;
}

.print-styling {
  height: 0;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.tooltip__value_wrapper {
  background-color: rgba(0, 0, 0, .06);
  border-radius: 2px;
  padding: 2px 6px;
  display: flex;
}

.location__facilities_trigger {
  z-index: 1000;
  height: 36px;
  border-radius: 2px;
  flex: none;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 77px;
}

.location__facilities_trigger.hidden {
  display: none;
}

.location__facilities_content {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

.location__facilities_content.is--hidden {
  height: 0;
}

.location__facilities_content.is--shown {
  height: auto;
}

.location__facilities_icon {
  margin-right: 8px;
}

.location__facilities_expand {
  height: 36px;
  color: #fff;
  cursor: pointer;
  background-image: none;
  border-radius: 2px;
  flex: none;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.location__facilities_expand:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.location__facilities_expand.bg-primary.hidden {
  display: none;
}

.location__facilities_contract {
  height: 36px;
  color: #fff;
  cursor: pointer;
  background-image: none;
  border-radius: 2px;
  flex: none;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.location__facilities_contract:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.location__facilities_contract.bg-primary.hidden {
  display: none;
}

.location__facilities_content-wrapper {
  width: 100%;
  flex-wrap: wrap;
  margin-top: 16px;
  display: flex;
}

.location__facilities_header {
  flex: 1;
  align-items: center;
  margin-left: 6px;
  display: flex;
}

.location__facilities_header.hidden {
  display: none;
}

.location__facilities_title_loading {
  text-transform: none;
  flex: 1;
  line-height: 140%;
  display: flex;
}

.location__facilities_header--loading {
  height: 36px;
  flex: 1;
  align-items: center;
  margin-left: 6px;
  display: flex;
}

.location__facilities_header--loading.hidden {
  display: none;
}

.map-geo-select {
  z-index: 999;
  min-height: 44px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 32px;
  margin-right: 53px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, .2), 0 1px 6px -2px rgba(0, 0, 0, .3);
}

.mag-geo__dropdown_wrap {
  z-index: 1;
  flex-direction: column;
  margin: 6px;
  display: flex;
  position: relative;
}

.mag-geo__dropdown_wrap.disabled {
  opacity: .4;
}

.warning-modal {
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.warning-modal.hidden {
  display: none;
}

.warning {
  width: 450px;
  grid-column-gap: 16px;
  grid-row-gap: 12px;
  background-color: #fff;
  border-radius: 3px;
  flex-direction: column;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 16px 24px;
  display: flex;
  position: absolute;
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, .2);
}

.warning__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.warning-title {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 4.5fr 1fr;
  grid-auto-columns: 1fr;
  font-size: .7em;
}

.warning__actions {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-top: 1px solid #f0f0f0;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-self: stretch;
  align-items: center;
  padding-top: 16px;
  display: grid;
}

.button-wrapper {
  margin-right: -12px;
  display: flex;
}

.point-mapper__h1_arrow-toggle {
  z-index: 0;
  width: 36px;
  height: 36px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
  overflow: hidden;
}

.text-block-2 {
  width: 36px;
  height: 36px;
}

.fas {
  width: auto;
}

.point-mapper__arrow-down, .point-mapper__arrow-up {
  width: 36px;
  height: 36px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.point-mapper__h1_trigger-arrow {
  width: 36px;
  height: 36px;
  color: #333;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: .3em;
}

.map-download {
  z-index: 999;
  width: 34px;
  height: 34px;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, .2);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  margin-right: 10px;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, .2), 1px 1px 6px -2px rgba(0, 0, 0, .3);
}

.map-download.hover-text-primary.disabled {
  margin-bottom: 100px;
}

.map-download.disabled {
  color: #9e9e9e;
  cursor: auto;
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.map-download.disabled:hover {
  color: #9e9e9e;
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.plate {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.plate.bg-primary, .bg-primary {
  background-color: #39ad84;
}

.bg-secondary {
  background-color: #3950ad;
}

.text-primary {
  color: #39ad84;
}

.hover-bg-primary-light:hover, .bg-primary-light {
  background-color: rgba(57, 173, 132, .2);
}

.hover-text-primary:hover {
  color: #39ad84;
}

.tab-notice {
  z-index: 999;
  transform-origin: 100% 100%;
  color: #fff;
  text-align: right;
  align-items: center;
  margin-bottom: 150px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  position: fixed;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 22px;
  transform: rotate(-90deg);
}

.tab-notice.hidden {
  display: none;
}

.tab-notice__text {
  z-index: 1;
  align-self: center;
  display: inline-block;
  position: relative;
}

.tab-notice__inner {
  height: 44px;
  white-space: nowrap;
  cursor: pointer;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  transition: all .2s;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.tab-notice__inner:hover {
  padding-bottom: 52px;
}

.tab-notice__content {
  color: #fff;
  padding: 12px;
}

.point-mapper-content__error {
  margin-bottom: 16px;
}

.error {
  color: #666;
  background-color: #f0f0f0;
  border-radius: 2px;
  align-items: center;
  padding: 4px 6px;
  font-size: .9em;
  display: flex;
}

.error__icon {
  padding-right: 6px;
}

.facility-tooltip__scroll-wrapper {
  height: 100%;
  align-self: stretch;
  margin-top: 6px;
  overflow: auto;
}

.facility-tooltip__open-modal {
  width: 100%;
  height: 32px;
  text-align: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 2px;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-size: .85em;
  display: flex;
}

.facility-tooltip__open-modal:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.facility-tooltip__showing {
  width: 100%;
  border-top: 1px solid #ebebeb;
  padding-top: 8px;
  font-size: .85em;
  display: flex;
}

.facility-tooltip__showing_icon {
  width: 24px;
  height: 24px;
}

.facility-info {
  z-index: 5;
  max-height: 40vh;
  max-width: 840px;
  background-color: #fff;
  border-radius: 4px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 12px 16px 20px;
  display: none;
  position: absolute;
  top: auto;
  bottom: 20px;
  left: 20%;
  right: 20%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
}

.facility-info.webflow {
  display: flex;
}

.facility-info__title {
  margin-top: 0;
  margin-bottom: 0;
}

.facility-info__close {
  z-index: 1;
  width: 36px;
  height: 36px;
  color: rgba(0, 0, 0, .63);
  cursor: pointer;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 2px;
  transition: all .2s;
  display: flex;
}

.facility-info__close:hover {
  color: #000;
}

.facility-info__close.hidden {
  display: none;
}

.facility-info__content {
  flex: 1;
  overflow: auto;
}

.facility-info__items {
  border-top: 1px solid #e6e6e6;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  margin-right: 6px;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: .85em;
  display: flex;
}

.facility-info__item {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  flex-direction: column;
  padding-top: 6px;
  padding-bottom: 4px;
  font-weight: 500;
  line-height: 150%;
  display: flex;
}

.facility-info__item.last {
  border-bottom-style: none;
}

.facility-info__item_label {
  text-transform: capitalize;
  font-weight: 700;
  display: flex;
}

.facility-info__item_value {
  font-weight: 400;
  display: flex;
}

.facility-info__header {
  grid-column-gap: 8px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  margin-bottom: 10px;
  display: grid;
}

.facility-info__print {
  z-index: 1;
  width: 36px;
  height: 36px;
  color: rgba(0, 0, 0, .63);
  cursor: pointer;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 2px;
  transition: all .2s;
  display: flex;
}

.facility-info__print:hover {
  color: #000;
}

.facility-info__print.hidden {
  display: none;
}

.map-title {
  z-index: 999;
  background-color: #fff;
  border-radius: 6px;
  margin-left: 24px;
  margin-right: 24px;
  padding: 12px;
  font-size: 1.2em;
  font-weight: 700;
  display: block;
  position: absolute;
  top: 16px;
  bottom: auto;
  left: auto;
  right: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
}

.map-title.webflow {
  display: flex;
}

.map-title.hidden {
  display: none;
}

.rich-data__print {
  z-index: 100;
  width: 40px;
  height: 40px;
  color: #333;
  background-color: #f0f0f0;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 82px;
  bottom: auto;
  left: auto;
  right: 12px;
}

.rich-data__print:hover {
  background-color: rgba(0, 0, 0, .1);
}

.map-print {
  z-index: 999;
  width: 95%;
  max-width: 650px;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-items: center;
  position: absolute;
  top: auto;
  bottom: 24px;
  left: auto;
  right: auto;
}

.map-print.hidden {
  display: none;
}

.map-point-legend {
  z-index: 999;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: -6px;
  display: flex;
  position: relative;
}

.map-point-legend.hidden {
  display: none;
}

.map-print__choropleth-legend {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  box-shadow: 0 1px 6px -2px rgba(0, 0, 0, .3), 0 0 0 -1px rgba(0, 0, 0, .2);
}

.map-print__legend_label {
  color: #999;
  letter-spacing: .1em;
  text-transform: uppercase;
  align-items: center;
  margin-right: 4px;
  font-size: .7em;
  line-height: 1em;
  display: flex;
}

.map-print__legend_wrap {
  height: 19px;
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: max-content;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-right: -2px;
  display: flex;
}

.map-print__legend_loading {
  height: 19px;
  min-width: 60px;
  text-align: center;
  letter-spacing: .2px;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, .1);
  border-radius: 2px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: .6em;
  display: flex;
}

.map-print__legend_loading.hidden {
  display: none;
}

.point-legend {
  background-color: #fff;
  border-radius: 4px;
  flex: none;
  align-items: center;
  margin-bottom: 6px;
  margin-right: 6px;
  padding: 6px;
  font-size: 13px;
  display: flex;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, .2), 0 1px 6px -2px rgba(0, 0, 0, .3);
}

.point-legend__color {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 100px;
  flex: none;
  margin-right: 6px;
}

.point-legend__color.demo {
  background-color: #39ad84;
}

.point-legend__text {
  flex: none;
}

.map-bottom-items {
  z-index: 999;
  width: 95%;
  max-width: 650px;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 32px;
  left: auto;
  right: auto;
}

.map-bottom-items.hidden {
  display: none;
}

.point-legend__remove {
  width: 24px;
  height: 24px;
  color: #707070;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 100px;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  display: flex;
}

.point-legend__remove:hover {
  color: #333;
  background-color: rgba(0, 0, 0, .1);
}

.point-legend__remove.hidden {
  display: none;
}

.map-print__point-legend {
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: -6px;
  display: flex;
}

.location__facilities_google-link {
  white-space: nowrap;
}

.facility-info__google-map-text {
  width: 100%;
}

.facility-info__view-google-map {
  color: #333;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 4px;
  align-self: flex-start;
  align-items: center;
  margin-bottom: 20px;
  padding: 6px 10px 6px 6px;
  text-decoration: none;
  display: flex;
}

.facility-info__view-google-map:hover {
  color: #000;
  background-color: rgba(0, 0, 0, .1);
}

.facility-info__view-google-map.hidden {
  display: none;
}

.facility-info__google-map-icon {
  margin-right: 4px;
}

.profile-indicator__table {
  flex-direction: column;
  padding-bottom: 1em;
  display: flex;
}

.profile-indicator__table_row {
  border-bottom: 1px solid rgba(0, 0, 0, .06);
  grid-template-rows: auto;
  grid-template-columns: 33.3333%;
  grid-auto-columns: 33.3333%;
  grid-auto-flow: column;
  font-size: 13px;
  display: grid;
}

.profile-indicator__table_row:hover {
  background-color: rgba(0, 0, 0, .06);
}

.profile-indicator__table_row.profile-indicator__table_row--header {
  background-color: rgba(255, 255, 255, .06);
  border-bottom: 1px solid #707070;
  grid-auto-columns: 33.3333%;
  font-weight: 700;
  box-shadow: 0 10px 10px -9px rgba(0, 0, 0, .1);
}

.profile-indicator__table_row.profile-indicator__table_row--alt {
  background-color: rgba(255, 255, 255, .06);
}

.profile-indicator__table_row.profile-indicator__table_row--2-col {
  grid-template-columns: minmax(120px, 50%);
  grid-auto-columns: minmax(120px, 50%);
}

.profile-indicator__table_cell {
  white-space: nowrap;
  border-left: 1px solid rgba(0, 0, 0, .06);
  padding: 8px;
  overflow: hidden;
}

.profile-indicator__table_cell.profile-indicator__table_cell--first {
  border-left-style: none;
}

.profile-indicator__table_content {
  max-height: 300px;
}

.div-block, .profile-indicator__table_show-more {
  flex-direction: column;
  display: flex;
}

.profile-indicator__table_show-more.hidden {
  display: none;
}

.profile-indicator__table_showing {
  margin-top: 1em;
  font-size: 13px;
}

.profile-indicator__table_load-more {
  width: 100%;
  color: #333;
  text-align: center;
  background-color: #f0f0f0;
}

.profile-indicator__table_inner {
  max-height: 400px;
  margin-bottom: 2em;
  overflow: hidden;
}

.map__legend-block_value {
  flex: 1;
}

.location__facilities_download-all {
  height: 36px;
  grid-column-gap: 4px;
  grid-row-gap: 16px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 2px;
  flex: none;
  grid-template-rows: auto;
  grid-template-columns: 1fr max-content;
  grid-auto-columns: 1fr;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  display: grid;
}

.location__facilities_download-all:hover {
  background-color: rgba(0, 0, 0, .1);
}

.location__facilities_download-all.location__facilities_download-all--footer {
  margin-top: 8px;
  margin-left: 8px;
  display: none;
}

.location__facilities_header-wrapper {
  flex: 1;
  align-items: center;
  display: flex;
}

.point-mapper-options {
  z-index: 999;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, .2), 0 1px 6px -2px rgba(0, 0, 0, .3);
}

.point-mapper__filters {
  border-bottom: 1px solid #ebebeb;
}

.point-mapper__legend {
  padding: 10px;
  display: flex;
}

.point-mapper-options__filters_header {
  padding-left: 10px;
  display: flex;
}

.point-mapper-options__filters_content {
  border-top: 1px solid rgba(0, 0, 0, .1);
  flex-wrap: wrap;
  padding-top: 8px;
  padding-right: 10px;
  display: flex;
}

.point-mapper-options__filter {
  width: 50%;
  flex-direction: column;
  padding-bottom: 10px;
  padding-left: 10px;
  display: flex;
}

.point-mapper-options__filter.disabled {
  opacity: .4;
}

.point-mapper-options__current-filters, .div-block-2 {
  width: 100%;
}

.div-block-3 {
  width: 100%;
  display: flex;
}

.map-options__filter-row {
  width: 100%;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 46px;
  display: flex;
  position: relative;
}

.map-options__filter-row.hidden {
  display: none;
}

.mapping-options__remove-filter {
  width: 36px;
  height: 36px;
  color: #333;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 3px;
  flex: none;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 10px;
}

.mapping-options__remove-filter:hover {
  background-color: rgba(0, 0, 0, .1);
}

.mapping-options__remove-filter:active {
  background-color: rgba(0, 0, 0, .06);
}

.mapping-options__remove-filter.is--hidden {
  color: #c5c5c5;
  cursor: auto;
  background-color: rgba(0, 0, 0, .06);
  display: none;
}

.map-options__filter-labels {
  width: 100%;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 46px;
  display: flex;
}

.map-options__filter-labels.hidden {
  display: none;
}

.mapping-options__add-filter {
  height: 36px;
  color: #333;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 3px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: .9em;
  text-decoration: none;
  display: flex;
}

.mapping-options__add-filter:hover {
  background-color: rgba(0, 0, 0, .1);
}

.mapping-options__add-filter:active {
  background-color: rgba(0, 0, 0, .06);
}

.mapping-options__add-filter.hidden {
  display: none;
}

.profile-indicator__filters-wrapper {
  width: 80%;
  flex-wrap: wrap;
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
}

.profile-indicator__filter-labels {
  width: 100%;
  padding-top: 5px;
  padding-right: 43px;
  display: flex;
}

.profile-indicator__filter-row {
  width: 100%;
  padding-top: 5px;
  display: flex;
  position: relative;
}

.profile-indicator__add-filter {
  height: 36px;
  color: #333;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 3px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  text-decoration: none;
  display: flex;
}

.profile-indicator__add-filter:hover {
  background-color: rgba(0, 0, 0, .1);
}

.profile-indicator__add-filter:active {
  background-color: rgba(0, 0, 0, .06);
}

.profile-indicator__filter {
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
  display: flex;
}

.profile-indicator__filter.disabled {
  opacity: .4;
  cursor: auto;
}

.profile-indicator__filter_menu {
  z-index: 10;
  flex-direction: column;
  align-items: stretch;
  font-size: .9em;
  display: flex;
  position: relative;
}

.profile-indicator__remove-filter {
  width: 36px;
  height: 36px;
  color: #333;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 3px;
  flex: none;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  margin-left: 5px;
  display: flex;
}

.profile-indicator__remove-filter:hover {
  background-color: rgba(0, 0, 0, .1);
}

.profile-indicator__remove-filter:active {
  background-color: rgba(0, 0, 0, .06);
}

.profile-indicator__remove-filter.is--hidden {
  color: #c5c5c5;
  cursor: auto;
  background-color: rgba(0, 0, 0, .06);
  display: none;
}

.profile-indicator__new-filter {
  height: 36px;
  color: #333;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 3px;
  flex: 1;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  text-decoration: none;
  display: flex;
}

.profile-indicator__new-filter:hover {
  background-color: rgba(0, 0, 0, .1);
}

.profile-indicator__new-filter:active {
  background-color: rgba(0, 0, 0, .06);
}

.profile-indicator__new-filter.is--hidden {
  color: #c5c5c5;
  cursor: auto;
  background-color: rgba(0, 0, 0, .06);
  display: none;
}

.profile-indicator__filter-buttons {
  width: 52px;
  justify-content: flex-start;
  margin-left: -5px;
  display: flex;
}

.text-block-3 {
  padding-right: 6px;
}

.walkthrough {
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, .19);
}

.walkthrough.is--right {
  height: 2.6em;
  color: #fff;
  border-radius: 2px;
  align-self: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
  padding: 1em;
  font-size: 13px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 100%;
  right: auto;
}

.walkthrough.is--search-walkthrough {
  border-radius: 2px;
  align-items: center;
  margin-right: 1em;
  padding-left: 1em;
  padding-right: .5em;
  display: flex;
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.walkthrough.is--below {
  height: 2.6em;
  color: #fff;
  border-radius: 2px;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-top: 1em;
  padding: 1em;
  font-size: 13px;
  display: flex;
  position: absolute;
  top: 100%;
  bottom: auto;
}

.is--walkthrough-text {
  white-space: nowrap;
}

.walkthrough-chip {
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}

.walkthrough-chip.is--left {
  margin-left: -4px;
  position: absolute;
  top: auto;
  bottom: auto;
  left: 0%;
  right: auto;
}

.walkthrough-chip.is---top {
  margin-top: -4px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: auto;
}

.profile-indicator__new-filter-2 {
  height: 36px;
  color: #333;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 3px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  font-size: .9em;
  text-decoration: none;
  display: flex;
}

.profile-indicator__new-filter-2:hover {
  background-color: rgba(0, 0, 0, .1);
}

.profile-indicator__new-filter-2:active {
  background-color: rgba(0, 0, 0, .06);
}

.point-mapper__h2_facet {
  background-color: rgba(0, 0, 0, .05);
  border-radius: 2px;
  align-self: center;
  margin-bottom: 0;
  margin-left: .5em;
  margin-right: .5em;
  padding-left: .5em;
  padding-right: .5em;
  font-size: .8em;
  display: none;
}

.point-mapper__h2_facet.is--shown {
  display: flex;
}

.point-mapper__h1_facet {
  background-color: rgba(0, 0, 0, .05);
  border-radius: 2px;
  flex: none;
  align-self: center;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: .5em;
  padding-left: .5em;
  padding-right: .5em;
  font-size: .8em;
  display: none;
}

.point-mapper__h1_facet.is--shown {
  display: flex;
}

.documentation__container {
  max-width: 1140px;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  flex-direction: column;
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-items: start;
  margin-right: auto;
  display: grid;
}

.documentation__label {
  width: 100%;
  color: #000;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.4em;
}

.documentation__label.documentation__label--light {
  font-weight: 400;
}

.styles__class-label {
  color: #222;
  background-color: #f5f5f5;
  border-radius: 6px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 8px;
  padding: 4px 10px;
  font-family: Inconsolata, monospace;
}

.styles__class-label.styles__class-label--primary {
  color: #dadada;
  background-color: #222;
}

.documentation__component-wrap {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-bottom: 1px solid #e6e6e6;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: grid;
  position: relative;
}

.documentation__component-wrap.is--75 {
  width: 75%;
}

.documentation__component-wrap.is--50 {
  width: 50%;
}

.documentation__main {
  width: 100%;
  display: flex;
}

.documentation__section {
  border-bottom: 1px solid #e6e6e6;
  align-items: center;
  padding: 70px 5%;
  display: flex;
}

.documentation__grid-4-col {
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-wrap: wrap;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-items: start;
  margin-bottom: 10px;
  display: grid;
}

.documentation__class-wrapper {
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
  display: flex;
}

.documentation__menu_inner {
  height: 100%;
  flex-direction: column;
  flex: 1;
  padding-left: 20%;
  padding-right: 24px;
  display: flex;
  overflow: auto;
}

.documentation__h2 {
  color: #a1a1a1;
  letter-spacing: .2em;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 14px;
}

.documentation__menu {
  width: 20%;
  height: 100vh;
  min-width: 250px;
  background-color: #f5f5f5;
  justify-content: flex-end;
  padding-top: 70px;
  padding-bottom: 24px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.documentation__h1 {
  letter-spacing: .2em;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 16px;
}

.documentation__content {
  flex: 1;
}

.documentation__block {
  width: 100%;
  border-radius: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.documentation__block.is--full {
  padding-bottom: 2em;
}

.documentation__h3 {
  color: #a1a1a1;
  letter-spacing: .2em;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 400;
}

.point-filters__filters {
  width: 100%;
}

.point-filters_header {
  height: 36px;
  padding-right: 72px;
  display: flex;
  position: relative;
}

.point-filters__title {
  flex: 1;
  align-items: center;
  padding-left: 11px;
  padding-right: 11px;
  display: flex;
  overflow: hidden;
}

.point-filters__header-toggle {
  width: 36px;
  height: 36px;
  cursor: pointer;
  border-radius: 2px;
  flex-direction: column;
  flex: none;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 36px;
  overflow: hidden;
}

.point-filters__header-toggle:hover {
  background-color: rgba(0, 0, 0, .06);
}

.point-filters__header-toggle.is--v2 {
  right: 0;
}

.point-filters__header-close {
  width: 36px;
  height: 36px;
  cursor: pointer;
  border-radius: 2px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
  overflow: hidden;
}

.point-filters__header-close:hover {
  background-color: rgba(0, 0, 0, .06);
}

.point-filters__labels {
  width: 100%;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 46px;
  display: flex;
}

.point-filters__labels.hidden {
  display: none;
}

.point-filters_content {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, .1);
  flex-wrap: wrap;
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
}

.point-filters__filter-row {
  width: 100%;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 46px;
  display: flex;
  position: relative;
}

.point-filters__filter-row.hidden {
  display: none;
}

.point-filters__add-filter {
  height: 36px;
  color: #333;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 3px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: .9em;
  text-decoration: none;
  display: flex;
}

.point-filters__add-filter:hover {
  background-color: rgba(0, 0, 0, .1);
}

.point-filters__add-filter:active {
  background-color: rgba(0, 0, 0, .06);
}

.point-filters__add-filter.hidden {
  display: none;
}

.point-filters__filter {
  max-width: 48%;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
  display: flex;
}

.point-filters__filter.disabled {
  opacity: .4;
  cursor: auto;
}

.point-filters__filter-label {
  width: 50%;
  color: #999;
  letter-spacing: .1em;
  text-transform: uppercase;
  margin-bottom: 4px;
  font-size: .7em;
  line-height: 1em;
  display: flex;
}

.point-filters__filter-menu {
  z-index: 10;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  font-size: .9em;
  display: flex;
  position: relative;
}

.point-filters__remove-filter {
  width: 36px;
  height: 36px;
  color: #333;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 3px;
  flex: none;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 10px;
}

.point-filters__remove-filter:hover {
  background-color: rgba(0, 0, 0, .1);
}

.point-filters__remove-filter:active {
  background-color: rgba(0, 0, 0, .06);
}

.point-filters__remove-filter.is--hidden {
  color: #c5c5c5;
  cursor: auto;
  background-color: rgba(0, 0, 0, .06);
  display: none;
}

.point-filters {
  z-index: 999;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  box-shadow: 0 0 0 -1px rgba(0, 0, 0, .2), 0 1px 6px -2px rgba(0, 0, 0, .3);
}

.point-filters.hidden {
  display: none;
}

.documention__description {
  margin-bottom: 2em;
  font-size: .9em;
}

.facility-tooltip__cluster {
  min-width: 16em;
  border-bottom: 1px solid #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  margin-left: -.2em;
  margin-right: -.2em;
  padding-left: .2em;
  padding-right: .2em;
  font-size: .85em;
  display: flex;
}

.tooltip__cluster-item {
  width: 100%;
  color: #333;
  cursor: default;
  border-bottom: 1px solid #e6e6e6;
  align-items: center;
  padding: .4em;
  text-decoration: none;
  display: flex;
}

.tooltip__cluster-item.is--last {
  border-bottom-style: none;
  border-bottom-color: rgba(230, 230, 230, 0);
}

.tooltip__cluster-title {
  margin-bottom: 0;
}

.tooltip__cluster-title.truncate {
  flex: 1;
}

.tootlip__cluster-facet {
  margin-bottom: 0;
  padding-left: 1.2em;
}

.tooltip__cluster-icon {
  width: 20px;
  height: 20px;
  background-color: #707070;
  border-radius: 100px;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-right: .5em;
  display: flex;
}

.tooltip__cluster-icon.is--demo-1 {
  background-color: #39ad84;
}

.tooltip__cluster-icon.is--demo-2 {
  background-color: #f04f4f;
}

.tooltip__cluster-icon.is--demo-3 {
  background-color: #ff3a8c;
}

.tooltip__cluster-icon_letters {
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  flex: none;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-bottom: 0;
  padding-top: 2px;
  font-size: .9em;
  font-weight: 700;
  line-height: 1;
  display: flex;
}

.tooltip__cluster-icon_letters.is--hidden {
  display: none;
}

.location-facilities__trigger--loading {
  height: 36px;
  color: #fff;
  cursor: pointer;
  background-image: none;
  border-radius: 2px;
  flex: none;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.location-facilities__trigger--loading:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.location-facilities__trigger--loading.bg-primary.hidden {
  display: none;
}

.location-facility__item--loading {
  width: 16px;
  margin-right: 4px;
}

.profile-indicator__version {
  width: 100%;
  border-top: 1px dashed #e6e6e6;
  border-bottom: 1px dashed #e6e6e6;
  margin-top: -1px;
  margin-bottom: -1px;
  padding-top: .8em;
  padding-bottom: .8em;
  font-size: .85em;
  line-height: 2;
}

.version-link {
  height: 1.8em;
  color: #333;
  background-color: #f0f0f0;
  background-image: none;
  border-radius: 2px;
  margin-left: .2em;
  margin-right: .2em;
  padding: 0 .4em;
  line-height: 1.8;
  display: inline-block;
}

.version-link:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.version-link.is--current {
  justify-content: center;
  align-items: center;
}

.version-link.no-link {
  cursor: default;
}

.version-link.no-link:hover {
  background-image: none;
}

.generic-modal {
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.generic-modal.hidden {
  display: none;
}

.generic-modal.is--demo {
  width: auto;
  height: 300px;
  position: static;
}

.modal {
  width: 450px;
  grid-column-gap: 16px;
  grid-row-gap: 12px;
  background-color: #fff;
  border-radius: 3px;
  flex-direction: column;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 16px 24px;
  display: flex;
  position: absolute;
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, .2);
}

.modal__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal__actions {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-top: 1px solid #f0f0f0;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-self: stretch;
  align-items: center;
  padding-top: 16px;
  display: grid;
}

.modal-title {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 4.5fr 1fr;
  grid-auto-columns: 1fr;
  font-size: .7em;
}

.highlight {
  z-index: 0;
  border: 1px solid #39ad84;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  box-shadow: 0 0 7px 2px #a3d8c5;
}

.highlight.hidden {
  display: none;
}

.highlight__notification {
  color: #fff;
  background-color: #39ad84;
  border-radius: 4px;
  justify-content: center;
  margin-bottom: 10px;
  padding: .3em .5em;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 100%;
  left: auto;
  right: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
}

.highlight__notification.is--align-right {
  justify-content: flex-end;
  right: 0;
}

.highlight__notification.is--align-left {
  justify-content: flex-start;
  left: 0;
}

.highlight__notification.hidden {
  display: none;
}

.highlight__chip {
  width: 8px;
  height: 8px;
  background-color: #39ad84;
  margin-bottom: -4px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: auto;
  transform: rotate(45deg);
}

.highlight__chip.is--align-right {
  margin-right: 8px;
  right: 0;
}

.highlight__chip.is--align-left {
  margin-left: 8px;
  left: 0;
}

.highlight__message {
  margin-bottom: 0;
}

.highlight-dummy {
  width: 200px;
  height: 100px;
  margin-top: 3em;
  position: relative;
}

.facility-tooltip__scroll {
  width: 100%;
  height: 100%;
  padding: 8px;
  overflow: auto;
}

.map-bottom-items--v2 {
  z-index: 999;
  width: 95%;
  max-width: 650px;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 32px;
  left: auto;
  right: auto;
}

.map-bottom-items--v2.hidden {
  display: none;
}

.point-filters__loading {
  flex: 1;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.loading-2 {
  height: 100%;
  color: #666;
  background-color: #f0f0f0;
  border-radius: 2px;
  flex: 1;
  align-items: center;
  padding: 4px 6px;
  font-size: .9em;
  display: flex;
}

.point-filters__no-data {
  color: #707070;
  flex: 1;
  margin-top: 5px;
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 10px;
}

.point-filters__no-data.hidden {
  display: none;
}

.map-credit {
  z-index: 1000;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, .5);
  margin-bottom: 0;
  padding: 5px 8px;
  font-size: .9em;
  line-height: 1;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.map-options__loading {
  flex: 1;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.map-options__no-data {
  color: #707070;
  flex: 1;
  margin-top: 5px;
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 10px;
}

.map-options__context--v2 {
  border-top: 1px dashed rgba(0, 0, 0, .1);
  justify-content: space-between;
  align-items: stretch;
  padding-left: 10px;
  padding-right: 10px;
  font-size: .8em;
  display: flex;
}

.map-options__context--v2.hidden {
  display: none;
}

.data-mapper-content__list_loading {
  z-index: 100;
  background-color: rgba(0, 0, 0, .03);
  border-radius: 4px;
  display: none;
  position: absolute;
  top: -6px;
  bottom: 0%;
  left: -6px;
  right: -6px;
  overflow: hidden;
}

.data-mapper-content__list_loading-grad {
  width: 50%;
  height: 100%;
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .1) 50%, rgba(0, 0, 0, 0) 102%);
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.data-mapper--v2 {
  z-index: 3;
  min-width: 300px;
  background-color: #fff;
  display: none;
  position: fixed;
  top: 56px;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.data-mapper--v2.webflow {
  display: flex;
  left: 0;
}

.data-category__h1_trigger--v2 {
  height: 36px;
  color: #fff;
  cursor: pointer;
  background-color: #f0f0f0;
  background-image: none;
  border-radius: 2px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  font-size: 14px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.data-category__h1_trigger--v2:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .05), rgba(0, 0, 0, .05));
}

.data-category__h1_trigger--v2.bg-primary {
  background-color: #39ad84;
}

.data-category__h2_trigger--v2 {
  height: 32px;
  color: #333;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 2px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
  padding-left: 12px;
  padding-right: 8px;
  font-size: .85em;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.data-category__h2_trigger--v2:hover {
  color: #000;
  background-color: rgba(0, 0, 0, .1);
}

.data-category__h2_trigger--v2.w--current {
  font-size: 1em;
}

.data-category__h3_trigger--v2 {
  height: 32px;
  color: #666;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
  border-radius: 2px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 6px;
  padding-left: 12px;
  padding-right: 8px;
  font-size: .85em;
  text-decoration: none;
  display: flex;
  position: relative;
}

.data-category__h3_trigger--v2:hover {
  background-color: rgba(0, 0, 0, .1);
}

.data-category__h3_trigger--v2.active {
  color: #333;
  font-weight: 500;
}

.data-category__h3_trigger--v2.active:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.data-mapper-content__list--v2 {
  height: auto;
  position: relative;
}

.data-mapper-content__list--v2.is--loading {
  filter: grayscale();
}

.data-category__h1_content--v2 {
  flex-direction: column;
  transition: max-height .4s cubic-bezier(.23, 1, .32, 1);
  display: flex;
  overflow: hidden;
}

.data-category__h1_content--v2.is--closed {
  max-height: 0;
}

.data-category__h2_content--v2 {
  flex-direction: column;
  transition: max-height .4s;
  display: flex;
  overflow: hidden;
}

.data-category__h2_content--v2.is--closed {
  max-height: 0;
}

.data-category__h3_content--v2 {
  transition: height .4s;
  position: relative;
}

.data-category__h3_content--v2.is--closed {
  height: 0;
}

.data-category--v2 {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 8px;
  display: flex;
  position: relative;
}

.tutorial-modal--v2 {
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.tutorial-modal--v2.webflow {
  display: flex;
}

.tutorial__slide-image--v2 {
  width: 100%;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 0;
  background-size: cover;
  flex: 1;
}

.tutorial__slide-image--v2._1 {
  height: 350px;
  background-image: none;
  background-position: 0 0;
  background-size: auto;
  position: relative;
}

.tutorial__slide-image--v2._2 {
  background-image: url('../images/slide-2.jpg');
}

.tutorial__slide-image--v2._4 {
  background-image: url('../images/slide-3.jpg');
  background-position: 0 0;
}

.tutorial__slide-image--v2._3 {
  background-image: url('../images/slide-4.jpg');
  background-position: 0 0;
}

.tutorial__slide-image--v2._5 {
  background-image: url('../images/slide-5.jpg');
  background-position: 0 0;
}

.tutorial__slide-image--v2._2b {
  background-image: url('../images/slide-2b.jpg');
}

.tutorial__slide-image--v2._7 {
  background-image: url('../images/slide-7.jpg');
  background-position: 50% 100%;
}

.panel-toggle__tooltip-2 {
  height: 28px;
  color: #333;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 4px;
  align-self: center;
  align-items: center;
  margin-left: 12px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: .9em;
  display: none;
  position: absolute;
  top: auto;
  bottom: auto;
  left: 100%;
  right: auto;
  box-shadow: 0 1px 9px rgba(0, 0, 0, .2);
}

.tutorial__slide-info-2 {
  z-index: 1;
  width: 100%;
  color: #fff;
  text-align: left;
  background-color: #333;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 24px;
  line-height: 140%;
  display: flex;
  position: relative;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .3);
}

.tutorial__slide-image {
  width: 100%;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 0;
  background-size: cover;
  flex: 1;
}

.tutorial__slide-image._2b {
  background-image: url('../images/slide-2b.jpg');
}

.tutorial__slide-image._3 {
  background-image: url('../images/slide-4.jpg');
  background-position: 0 0;
}

.tutorial__slide-image._5 {
  background-image: url('../images/slide-5.jpg');
  background-position: 0 0;
}

.tutorial__slide-image._7 {
  background-image: url('../images/slide-7.jpg');
  background-position: 50% 100%;
}

.tutorial__slide-image._4 {
  background-image: url('../images/slide-3.jpg');
  background-position: 0 0;
}

.tutorial__slide-image._2 {
  background-image: url('../images/slide-2.jpg');
}

.tutorial__slide-image._1 {
  height: 350px;
  background-image: url('../images/slide-1.jpg');
  background-position: 50% 0;
  background-size: cover;
  position: relative;
}

.tutorial-2 {
  width: 450px;
  grid-column-gap: 16px;
  grid-row-gap: 12px;
  background-color: #fff;
  border-radius: 3px;
  flex-direction: column;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 10px 24px 16px;
  display: flex;
  position: absolute;
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, .2);
}

.tutorial__slide_button-2 {
  color: #333;
  cursor: pointer;
  background-color: rgba(0, 0, 0, .1);
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.tutorial__slide_button-2:hover {
  color: #000;
  background-color: rgba(0, 0, 0, .1);
}

.tutorial__slide_button-2.next {
  color: #fff;
  background-color: #707070;
  margin-left: 12px;
}

.tutorial__slide_button-2.next:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.tutorial__slide_button-2.previous {
  width: 44px;
  height: 44px;
  margin-right: 12px;
}

.data-compare {
  z-index: 2;
  grid-row-gap: 1em;
  background-color: #fff;
  flex-direction: column;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 2px;
  padding: 22px 14px 14px 20px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.menu-h2 {
  font-size: 1em;
  line-height: 1;
}

.data-compare__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.button-text {
  margin-bottom: 0;
  line-height: 1;
}

.compare-list {
  grid-row-gap: .5em;
  flex-direction: column;
  display: flex;
}

.compare-list__item {
  background-color: #f0f0f0;
  border-radius: 2px;
  flex-direction: column;
  display: flex;
  position: relative;
}

.compare-item__inner {
  justify-content: space-between;
  align-items: center;
  padding-left: .8em;
  font-size: .95em;
  text-decoration: none;
  display: flex;
}

.compare-item__content {
  grid-row-gap: .5em;
  flex-direction: column;
  padding: .5em;
  display: flex;
}

.compare-item__text {
  letter-spacing: -.02em;
  margin-bottom: 0;
  line-height: 1;
}

.compare-item__expand-trigger {
  width: 44px;
  height: 44px;
  background-image: none;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.compare-item__expand-trigger:hover {
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
}

.compare-item__remove {
  width: 24px;
  height: 24px;
  color: #666;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: -.8em;
  bottom: auto;
  left: auto;
  right: -.8em;
}

.compare-item__remove:hover {
  color: #000;
}

.map-widgets {
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.map-widgets_credit {
  width: 100vw;
  height: 20px;
  background-color: rgba(0, 0, 0, .09);
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.map-widgets_zoom {
  width: 34px;
  height: 64px;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, .2);
  border-radius: 4px;
  position: absolute;
  bottom: 30px;
  right: 10px;
}

.div-block-4 {
  height: 200px;
  background-color: rgba(181, 54, 107, .2);
}

@media screen and (max-width: 991px) {
  .rich-data-content {
    padding-top: 44px;
    padding-left: 44px;
    padding-right: 44px;
  }

  .body {
    font-size: 14px;
  }

  .rich-data-nav {
    width: 44px;
  }

  .rich-data-nav__list {
    padding-top: 72px;
  }

  .location__facilities {
    display: flex;
  }

  .location-facility {
    width: 50%;
  }

  .rich-data-bumper {
    width: 44px;
  }

  .rich-data-nav__toggle {
    display: none;
  }

  .key-metric {
    width: 50%;
  }

  .bar-chart__labels {
    padding-left: 90px;
  }

  .bar-chart__underline {
    margin-left: 90px;
  }

  .bar-chart {
    font-size: .9em;
  }

  .profile-indicator__chart_body {
    padding-right: 12px;
  }

  .bar-chart__x-label {
    padding-left: 90px;
  }

  .bar-chart__row_label {
    width: 90px;
  }

  .page-header {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .content-block {
    padding-bottom: 40px;
  }

  .content-block.bg-green.first {
    margin-bottom: 40px;
    padding-top: 40px;
  }

  .profile-indicator__table_row {
    grid-auto-columns: 33.3333%;
  }

  .profile-indicator__table_showing {
    font-size: 13px;
  }

  .location__facilities_download-all.location__facilities_download-all--header {
    display: none;
  }

  .location__facilities_download-all.location__facilities_download-all--footer {
    grid-column-gap: 4px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr max-content;
    grid-auto-columns: 1fr;
    display: grid;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 1.6em;
  }

  h3 {
    font-size: 1.4em;
  }

  .rich-data-content {
    max-width: 76vw;
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .nav.narrow {
    padding-left: 32px;
    padding-right: 32px;
  }

  .map-location {
    max-width: 95vw;
  }

  .map-location__tags {
    max-width: 100%;
    justify-content: flex-start;
    align-self: center;
  }

  .rich-data-toggles {
    top: 190px;
  }

  .category-header {
    margin-bottom: 24px;
    padding-bottom: 6px;
  }

  .profile-indicator__chart_description {
    max-width: none;
    margin-bottom: 0;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .profile-indicator {
    padding-bottom: 20px;
  }

  .profile-indicator__chart_source, .bar-chart__labels {
    padding-left: 0;
  }

  .filter__dropdown_wrap {
    flex: 1;
  }

  .bar-chart__underline {
    margin-left: 0;
  }

  .profile-indicator__filters {
    margin-bottom: 0;
  }

  .bar-chart__rows {
    padding-bottom: 0;
  }

  .profile-indicator__chart_body {
    padding-right: 0;
  }

  .bar-chart__x-label {
    margin-top: 12px;
    padding-left: 0;
  }

  .bar-chart__row_tooltip {
    display: none;
  }

  .bar-chart__row {
    height: 36px;
    flex-direction: column;
    margin-bottom: 8px;
  }

  .bar-chart__row_label {
    width: 100%;
    max-width: none;
    justify-content: flex-start;
    padding-left: 0;
  }

  .bar-chart__label_value {
    display: none;
  }

  .nav__search_button {
    width: 44px;
  }

  .nav__content_title {
    width: 100%;
    min-width: 100px;
  }

  .nav__menu-trigger {
    margin-left: 6px;
  }

  .nav__link.tutorial__open {
    height: 37px;
    padding-right: 6px;
  }

  .point-mapper-toggles {
    top: 134px;
  }

  .panel-toggles {
    top: 190px;
  }

  .data-mapper-toggles {
    top: 134px;
  }

  .mapping-options__filter {
    flex: 1;
  }

  .tutorial {
    overflow: auto;
  }

  .nav__link_text {
    display: none;
  }

  .page-header {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .content-block {
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .content-block.bg-green.first {
    margin-bottom: 32px;
    padding-top: 32px;
  }

  .location__facilities_header {
    width: 100%;
    height: auto;
    flex-direction: column;
    flex: 0 auto;
    align-items: flex-start;
  }

  .map-geo-select {
    z-index: 500;
    bottom: 85px;
  }

  .mag-geo__dropdown_wrap {
    flex: 1;
  }

  .rich-data__print {
    display: none;
    top: 70px;
  }

  .map-bottom-items {
    width: 100%;
    padding-left: 10px;
    padding-right: 53px;
  }

  .div-block {
    flex: none;
  }

  .location__facilities_download-all, .location__facilities_header-wrapper {
    margin-bottom: 8px;
  }

  .point-mapper-options__filter, .profile-indicator__filter, .point-filters__filter {
    flex: 1;
  }

  .map-bottom-items--v2 {
    width: 100%;
    padding-left: 10px;
    padding-right: 52px;
  }

  .tutorial-2 {
    overflow: auto;
  }

  .div-block-5 {
    height: 96px;
    background-color: red;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 2em;
  }

  .rich-data-content {
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .section {
    margin-bottom: 48px;
  }

  .body {
    font-size: 13px;
  }

  .nav {
    padding-left: 10px;
    padding-right: 10px;
  }

  .nav.narrow {
    padding-left: 20px;
    padding-right: 20px;
  }

  .section-link {
    margin-top: -124px;
  }

  .panel-toggle {
    width: 44px;
    height: 44px;
  }

  .svg-icon {
    transform: rotate(0);
  }

  .breadcrumb {
    font-size: .7em;
  }

  .location-facility {
    width: 100%;
  }

  .location__title {
    margin-bottom: 16px;
  }

  .rich-data-nav__toggle {
    z-index: 999;
    background-color: #333;
    display: flex;
    top: auto;
    bottom: 48px;
    left: 12px;
    right: auto;
  }

  .location__facilities_sources {
    margin-left: -8px;
  }

  .sticky-header {
    top: 52px;
  }

  .sub-category-header__key-metrics_title {
    font-size: .8em;
  }

  .key-metric {
    width: 100%;
  }

  .dropdown-menu {
    z-index: 1;
  }

  .sticky-header__current-location {
    height: 32px;
    margin-left: 0;
    padding-left: 12px;
    padding-right: 12px;
  }

  .nav__content.narrow {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .nav__content_search {
    display: none;
  }

  .point-mapper {
    max-width: 83vw;
    display: none;
  }

  .data-mapper {
    max-width: 83vw;
    min-width: auto;
    display: none;
  }

  .mapping-options__filter_menu {
    z-index: 1;
  }

  .login {
    width: 90%;
    padding: 12px 16px;
  }

  .login__close {
    margin-right: -8px;
  }

  .login-form__actions {
    grid-template-rows: auto auto auto;
  }

  .tutorial {
    width: 90%;
    max-height: 90vh;
    grid-row-gap: 4px;
    padding: 10px 16px 16px;
  }

  .tutorial__close {
    margin-right: -6px;
  }

  .tutorial__slider {
    margin-left: -16px;
    margin-right: -16px;
  }

  .tutorial__slide-content {
    overflow: auto;
  }

  .tutorial__slide-info {
    margin-left: -32px;
    padding: 10px 12px;
    left: 16px;
    right: 16px;
  }

  .tutorial__slide_button.next {
    margin-left: 8px;
    margin-right: 16px;
  }

  .tutorial__slide_button.previous {
    margin-left: 16px;
    margin-right: 8px;
  }

  .location__tutorial {
    width: 24px;
    height: 24px;
  }

  .location__tutorial_text {
    display: none;
  }

  .page-header {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .content-block {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content-block.bg-green.first {
    margin-bottom: 16px;
  }

  .page-header__overline {
    font-size: .85em;
  }

  .page-header__title {
    align-items: stretch;
  }

  .page-header__website-link {
    justify-content: space-between;
  }

  .warning {
    width: 90%;
    grid-row-gap: 4px;
    padding: 4px 16px;
  }

  .map-bottom-items {
    padding-right: 40px;
  }

  .profile-indicator__filter_menu, .point-filters__filter-menu {
    z-index: 1;
  }

  .modal {
    width: 90%;
    grid-row-gap: 4px;
    padding: 4px 16px;
  }

  .data-mapper--v2 {
    display: none;
  }

  .tutorial__slide-info-2 {
    margin-left: -32px;
    padding: 10px 12px;
    left: 16px;
    right: 16px;
  }

  .tutorial-2 {
    width: 90%;
    max-height: 90vh;
    grid-row-gap: 4px;
    padding: 10px 16px 16px;
  }

  .tutorial__slide_button-2.next {
    margin-left: 8px;
    margin-right: 16px;
  }

  .tutorial__slide_button-2.previous {
    margin-left: 16px;
    margin-right: 8px;
  }
}

#w-node-_8370c3c3-dd27-5ba4-e185-11a67e6ccd44-2dd9ea49 {
  grid-area: span 1 / span 4 / span 1 / span 4;
}

#w-node-_138f8096-55b2-2cf7-756a-31f6ef2aede4-2dd9ea49 {
  align-self: start;
  justify-self: end;
}

#w-node-_2a3c695e-4c47-ff4f-0294-09c3cf7a9fe2-2dd9ea49, #w-node-_5c3e6bec-baae-1fe9-3f2a-222d0aaebade-2dd9ea49 {
  grid-area: span 1 / span 5 / span 1 / span 5;
}

#w-node-_80c1d4e4-3db7-9834-435b-fefcf113a3cc-2dd9ea49 {
  grid-area: 1 / 1 / 2 / 3;
}

#w-node-_2c610f29-c711-7689-3c43-fceb1157e41b-2dd9ea49 {
  grid-area: 2 / 1 / 3 / 2;
}

#w-node-_4cdd59b0-8837-5a43-7847-25fe1e234621-2dd9ea49 {
  grid-area: 2 / 2 / 3 / 3;
  align-self: center;
  justify-self: start;
}

#w-node-e1033b14-ea6f-0f0d-1fc3-909d66137000-2dd9ea49 {
  grid-area: 2 / 1 / 3 / 5;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa5c-2dd9ea49 {
  grid-area: 3 / 1 / 4 / 5;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa5d-2dd9ea49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa61-2dd9ea49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c855a-2dd9ea49 {
  grid-area: 1 / 1 / 2 / 2;
  justify-self: start;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c8568-2dd9ea49 {
  grid-area: 1 / 2 / 2 / 4;
  justify-self: center;
}

#w-node-_91faa97b-05e0-ee56-35d9-a4312e607bde-2dd9ea49, #w-node-_91faa97b-05e0-ee56-35d9-a4312e607be7-2dd9ea49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0cc195d0-8237-0a5c-88c5-6bd36b98a8c8-2dd9ea49, #w-node-_8d9eb87a-805a-ef6a-0ef3-bca9e2f0f20f-2dd9ea49 {
  justify-self: end;
}

#w-node-f25982b9-693e-74dc-9a2f-7590f031f5c0-95d9ea7e {
  grid-area: 1 / 1 / 2 / 2;
  justify-self: start;
}

#w-node-f25982b9-693e-74dc-9a2f-7590f031f5c3-95d9ea7e {
  grid-area: 1 / 2 / 2 / 4;
  justify-self: center;
}

#w-node-f25982b9-693e-74dc-9a2f-7590f031f5e4-95d9ea7e {
  grid-area: 1 / 4 / 2 / 5;
  justify-self: end;
}

#w-node-_4d72d44a-a353-deb9-cdd6-3e7554d9fdf7-95d9ea7e {
  grid-area: span 1 / span 7 / span 1 / span 7;
}

#w-node-_90685573-4695-014e-a5e9-bcacce32cf46-95d9ea7e {
  grid-area: span 1 / span 2 / span 1 / span 2;
  align-self: end;
}

#w-node-c5c6b4ad-353e-3fe1-6487-d2e40839bd81-95d9ea7e, #w-node-_5e0b3564-86ec-d83a-3bc6-4f0e27157bd9-95d9ea7e {
  grid-area: span 1 / span 3 / span 1 / span 3;
}

#w-node-a1fbc524-d46c-78fc-46af-4011e6b49689-e4d9ea83, #w-node-de90d542-cc0b-b32b-2c3c-57eeb685ffd2-e4d9ea83, #w-node-_5f4fd3a3-1c16-aa44-33f5-905265a58174-e4d9ea83, #w-node-_15e74dcc-4ebc-9e20-0dfb-8e62eff40f7c-e4d9ea83, #w-node-_673b600c-07de-f165-508c-adc7cca0f0c7-e4d9ea83, #indicator-version.w-node-_906a3f22-7504-cf26-ae0b-cef043e37405-e4d9ea83 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#indicator-version.w-node-e48665a8-2ee8-c02c-3dd4-bf46bd52b2c8-e4d9ea83 {
  grid-area: span 1 / span 4 / span 1 / span 4;
}

#w-node-_1c81a672-7c56-c120-094b-ca488c73ef76-e4d9ea83 {
  grid-area: 3 / 1 / 4 / 5;
}

#w-node-_1c81a672-7c56-c120-094b-ca488c73ef77-e4d9ea83 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1c81a672-7c56-c120-094b-ca488c73ef7b-e4d9ea83 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_8370c3c3-dd27-5ba4-e185-11a67e6ccd44-6ad9ea93 {
  grid-area: span 1 / span 4 / span 1 / span 4;
}

#w-node-_138f8096-55b2-2cf7-756a-31f6ef2aede4-6ad9ea93 {
  align-self: start;
  justify-self: end;
}

#w-node-_2a3c695e-4c47-ff4f-0294-09c3cf7a9fe2-6ad9ea93, #w-node-_5c3e6bec-baae-1fe9-3f2a-222d0aaebade-6ad9ea93 {
  grid-area: span 1 / span 5 / span 1 / span 5;
}

#w-node-_80c1d4e4-3db7-9834-435b-fefcf113a3cc-6ad9ea93 {
  grid-area: 1 / 1 / 2 / 3;
}

#w-node-_2c610f29-c711-7689-3c43-fceb1157e41b-6ad9ea93 {
  grid-area: 2 / 1 / 3 / 2;
}

#w-node-_4cdd59b0-8837-5a43-7847-25fe1e234621-6ad9ea93 {
  grid-area: 2 / 2 / 3 / 3;
  align-self: center;
  justify-self: start;
}

#w-node-e1033b14-ea6f-0f0d-1fc3-909d66137000-6ad9ea93 {
  grid-area: 2 / 1 / 3 / 5;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa5c-6ad9ea93 {
  grid-area: 3 / 1 / 4 / 5;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa5d-6ad9ea93 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa61-6ad9ea93 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c855a-6ad9ea93 {
  grid-area: 1 / 1 / 2 / 2;
  justify-self: start;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c8568-6ad9ea93 {
  grid-area: 1 / 2 / 2 / 4;
  justify-self: center;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c85a0-6ad9ea93 {
  grid-area: 1 / 4 / 2 / 5;
  justify-self: end;
}

#w-node-_91faa97b-05e0-ee56-35d9-a4312e607bde-6ad9ea93, #w-node-_91faa97b-05e0-ee56-35d9-a4312e607be7-6ad9ea93 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0cc195d0-8237-0a5c-88c5-6bd36b98a8c8-6ad9ea93, #w-node-_8d9eb87a-805a-ef6a-0ef3-bca9e2f0f20f-6ad9ea93 {
  justify-self: end;
}

#w-node-_8370c3c3-dd27-5ba4-e185-11a67e6ccd44-d3a5d4ea {
  grid-area: span 1 / span 4 / span 1 / span 4;
}

#w-node-_138f8096-55b2-2cf7-756a-31f6ef2aede4-d3a5d4ea {
  align-self: start;
  justify-self: end;
}

#w-node-_2a3c695e-4c47-ff4f-0294-09c3cf7a9fe2-d3a5d4ea, #w-node-_5c3e6bec-baae-1fe9-3f2a-222d0aaebade-d3a5d4ea {
  grid-area: span 1 / span 5 / span 1 / span 5;
}

#w-node-_80c1d4e4-3db7-9834-435b-fefcf113a3cc-d3a5d4ea {
  grid-area: 1 / 1 / 2 / 3;
}

#w-node-_2c610f29-c711-7689-3c43-fceb1157e41b-d3a5d4ea {
  grid-area: 2 / 1 / 3 / 2;
}

#w-node-_4cdd59b0-8837-5a43-7847-25fe1e234621-d3a5d4ea {
  grid-area: 2 / 2 / 3 / 3;
  align-self: center;
  justify-self: start;
}

#w-node-e1033b14-ea6f-0f0d-1fc3-909d66137000-d3a5d4ea {
  grid-area: 2 / 1 / 3 / 5;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa5c-d3a5d4ea {
  grid-area: 3 / 1 / 4 / 5;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa5d-d3a5d4ea {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d59f773f-aa49-25bd-47c6-0df91218fa61-d3a5d4ea {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c855a-d3a5d4ea {
  grid-area: 1 / 1 / 2 / 2;
  justify-self: start;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c8568-d3a5d4ea {
  grid-area: 1 / 2 / 2 / 4;
  justify-self: center;
}

#w-node-_2331a044-806f-907c-13ce-926b3e9c85a0-d3a5d4ea {
  grid-area: 1 / 4 / 2 / 5;
  justify-self: end;
}

#w-node-_91faa97b-05e0-ee56-35d9-a4312e607bde-d3a5d4ea, #w-node-_91faa97b-05e0-ee56-35d9-a4312e607be7-d3a5d4ea {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0cc195d0-8237-0a5c-88c5-6bd36b98a8c8-d3a5d4ea, #w-node-_8d9eb87a-805a-ef6a-0ef3-bca9e2f0f20f-d3a5d4ea {
  justify-self: end;
}

@media screen and (max-width: 991px) {
  #w-node-_4d72d44a-a353-deb9-cdd6-3e7554d9fdf7-95d9ea7e {
    grid-area: span 1 / span 4 / span 1 / span 4;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_2331a044-806f-907c-13ce-926b3e9c8568-2dd9ea49, #w-node-f25982b9-693e-74dc-9a2f-7590f031f5c3-95d9ea7e, #w-node-_2331a044-806f-907c-13ce-926b3e9c8568-6ad9ea93, #w-node-_2331a044-806f-907c-13ce-926b3e9c8568-d3a5d4ea {
    grid-column: 2 / 4;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_2c610f29-c711-7689-3c43-fceb1157e41b-2dd9ea49 {
    grid-column-end: 3;
  }

  #w-node-_4cdd59b0-8837-5a43-7847-25fe1e234621-2dd9ea49 {
    grid-row: 3 / 4;
    grid-column-start: 1;
  }

  #w-node-f25982b9-693e-74dc-9a2f-7590f031f5e4-95d9ea7e {
    grid-column: 3 / 4;
  }

  #w-node-_4d72d44a-a353-deb9-cdd6-3e7554d9fdf7-95d9ea7e {
    grid-area: span 1 / span 3 / span 1 / span 3;
  }

  #w-node-_90685573-4695-014e-a5e9-bcacce32cf46-95d9ea7e {
    grid-area: span 1 / span 3 / span 1 / span 3;
    align-self: end;
  }

  #w-node-_2c610f29-c711-7689-3c43-fceb1157e41b-6ad9ea93 {
    grid-column-end: 3;
  }

  #w-node-_4cdd59b0-8837-5a43-7847-25fe1e234621-6ad9ea93 {
    grid-row: 3 / 4;
    grid-column-start: 1;
  }

  #w-node-_2c610f29-c711-7689-3c43-fceb1157e41b-d3a5d4ea {
    grid-column-end: 3;
  }

  #w-node-_4cdd59b0-8837-5a43-7847-25fe1e234621-d3a5d4ea {
    grid-row: 3 / 4;
    grid-column-start: 1;
  }
}


